import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifectionService } from 'src/app/services/notifection.service';
import { Location } from '@angular/common';
import { UserServiceService } from "src/app/services/user-service.service";
@Component({
  selector: 'app-product-update',
  templateUrl: './product-update.component.html',
  styleUrls: ['./product-update.component.css']
})
export class ProductUpdateComponent implements OnInit {

  logo: any;
  imageValues: any = [];
  form: FormGroup;
  invalid: string[] = [];
  ID: string;
  blogImage: any;
  urlPattern = '(https?:\/\/(?:www\.|(?!www))[^s\.]+.[^\s]{2,}|www\.[^\s]+.[^\s]{2,})';

  routeSub: any;

  constructor(
    private spinner: NgxSpinnerService,
    private _toster: NotifectionService,
    private userService: UserServiceService,
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private location: Location
  ) {
    this.routeSub = this.route.params.subscribe(params => {
      this.ID = params['id'];
    });
  }

  ngOnInit(): void {
    this.createForm();
    this.getDetails();
  }

  createForm() {
    this.form = this.fb.group({
      title: ['', Validators.required],
      link: ['', [Validators.required, Validators.pattern(this.urlPattern)]],
      description: ['', Validators.required],
      image: ['']
    });
  }
  checkLinkField() {
    const linkControl = this.form.get('link');
    if (linkControl) {
      if (linkControl.value && !linkControl.value.startsWith('http')) {
        linkControl.setErrors({ customError: 'Link must start with http or https' });
      } else {
        // If the control has no other errors, clear the custom error
        if (linkControl.errors && !linkControl.errors.pattern) {
          delete linkControl.errors.customError;
          if (Object.keys(linkControl.errors).length === 0) {
            linkControl.setErrors(null);
          }
        }
      }
    }
  }
  clearValidationMessage(key: string) {
    const index = this.invalid.indexOf(key);
    if (index !== -1) {
      this.invalid.splice(index, 1);
    }
  }

  validateForm() {
    this.invalid = [];
    const controls = this.form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        this.invalid.push(name);
      }
    }
  
    
    return this.invalid.length === 0;
  }

  cancel() {
    this.location.back();
  }

  edit() {
    if (this.validateForm()) {

      console.log('==<>____value---------')
      const formData = new FormData();
      this.spinner.show();
      if (this.logo) {
          formData.append('image', this.logo);
      }
      formData.append('title', this.form.get('title')?.value);
      formData.append('description', this.form.get('description')?.value);
      formData.append('link', this.form.get('link')?.value);

      formData.append('id', this.ID);

      this.userService.updateProduct(formData).subscribe(
        res => {
          this.spinner.hide();
          this._toster.showSuccess(res.message);
      
          this.location.back();
        },
        err => {
          this.spinner.hide();
          this._toster.showError(err.error.message);
        }
      );
    }
  }

  getDetails() {
    this.userService.getProductById(this.ID).subscribe((res) => {
      this.spinner.hide();
       this.blogImage = res.data?.image;
      
      this.form.patchValue({
        title: res.data?.title,
        description:res.data.description,
        link:res.data.link
      });
    },
    (err) => {
      this.spinner.hide();
      this._toster.showError(err.error.message);
    });
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

  onFileChange(event) {
    this.logo = event.target.files[0] && event.target.files[0];
  }

  onDeletePreviousImage(index: number) {
    let value = this.imageValues.splice(index, 1);
    let id = value[0]._id;
    let obj = {
      documentId: this.ID,
      id: id
    };
    this.userService.deleteArrayImage(obj).subscribe(res => {
      this.spinner.hide();
    }, err => {
      this._toster.showError(err.error.message);
      this.spinner.hide();
    });
  }
}

