<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<div class="main-content">
    <div class="title m-auto d-flex justify-content flex-column col-md-8 mb-3">
        <h3>Add Product</h3>
        <button class="form-control btn btn-primary" (click)="cancel()" style="margin-left: 83%; width: 14%;"
            type="button">
            Go Back
        </button>
    </div>

    <div class="mb-5 mt-5">
        <div [formGroup]="form" class="d-flex justify-content flex-column form-group col-md-8 m-auto">
            <div class="mb-3 d-flex flex-column align-items-start">
                <label for="title">Title</label>
                <div class="input-group ">
                    <input type="text" class="form-control"  onkeypress="return event.keyCode !== 32"formControlName="title"
                           (input)="clearValidationMessage('title')">
                </div>
                <span *ngIf="this.invalid?.includes('title')" class="danger">
                    <span class="fas fa-info-circle mr-2"></span>Title is required!
                </span>
            </div>
            <div class="mb-3 d-flex flex-column align-items-start">
                <label for="image">Image</label>
                <div class="input-group ">
                    <input type="file" class="form-control" multiple="true" accept="image/jpeg,image/png"
                           formControlName="image" (change)="onFileChange($event); clearValidationMessage('image')">
                </div>
                <span *ngIf="this.invalid?.includes('image')" class="danger">
                    <span class="fas fa-info-circle mr-2"></span>Image is required!
                </span>
            </div>
            <div class="mb-3 d-flex flex-column align-items-start">
                <label for="link">Link</label>
                <div class="input-group">
                    <input type="text" class="form-control" formControlName="link"
                           (input)="clearValidationMessage('link')">
                </div>
                <span *ngIf="invalid.includes('link') && form.get('link').errors?.required" class="danger">
                    <span class="fas fa-info-circle mr-2"></span>Link is required!
                </span>
                <span *ngIf="invalid.includes('link') && form.get('link').errors?.pattern" class="danger">
                    <span class="fas fa-info-circle mr-2"></span>Invalid link!
                </span>
            </div>
            <div class="mb-3 d-flex flex-column align-items-start">
                <label for="description">Description</label>
                <div class="input-group ">
                    <textarea class="form-control" onkeypress="return event.keyCode !== 32" formControlName="description"
                              (input)="clearValidationMessage('description')"></textarea>
                </div>
                <span *ngIf="this.invalid?.includes('description')" class="danger">
                    <span class="fas fa-info-circle mr-2"></span>Description is required!
                </span>
            </div>
        </div>
    </div>
    <div class="col-md-8 m-auto"style="margin-top:-4% !important"> 
        <button class="btn btn-info mr-2" (click)="edit()">Add</button>
        <button class="btn btn-light" (click)="cancel()">Cancel</button>
    </div>
</div>
