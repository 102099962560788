<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<div class="main-content">
  <section class="section">
    <div class="section-header">
      <!-- <h1> Admin Profile </h1> -->
    </div>
    <div class="section-body">
      <div class="">
        <div class="">
          <div class="card">
            <div class="card-body">
              <div class="d-flex flex-column  align-items-center header-div">
                <div class="mb-5 logo-container">
                  <img class="img-logo" *ngIf="url?.includes('icon-library')" src="https://api.dicebear.com/5.x/initials/svg?seed=Admin" alt="IMG">
                  <img class="img-logo" style="border: 1px solid grey" *ngIf="!url.includes('https')" [src]="getImageUrl(url)" alt="IMG">
                  <input type="file" class="input-file" #profileImage  accept="image/*" (change)="upload($event)">
                    <div class="liquid-container" (click)="profileImage.click()">
                      <span class="fas fa-camera mr-2 camera-logo"></span>
                    </div>
                 
                </div>

                <div class="form-container mt-3">
                  <div class="d-flex flex-column ">
                    <h3 *ngIf="!editflag" class="d-flex">Name : {{name}}</h3>
                    <h3 *ngIf="!editflag" class="d-flex">Email : {{email}}</h3>

                    <div *ngIf="editflag" [formGroup]="form" class="d-flex justify-content flex-column form-group">

                      
                      <div class="input-group mb-3 d-flex flex-column align-items-start">
                        <div class="d-flex align-center">
                          <h4  class="d-flex mr-2">Name:  </h4>
                          <input  type="text" class="form-control" pattern="[a-zA-Z ]*" formControlName="username"  placeholder="Enter the username" name="username" (keyup)="onChange('username')">
                        </div>
                        <span *ngIf="invalid.includes('username') && !this.form.get('username').value" style="margin-left : 72px" class="danger mb-2"><span class="fas fa-info-circle mr-2"></span>Name is required !</span>
                        <span *ngIf="invalid.includes('username') && this.form.get('username').value" style="margin-left : 72px" class="danger mb-2"><span class="fas fa-info-circle mr-2"></span>Please enter valid Name !</span>
                      </div>	

                      <div class="input-group mb-3 d-flex flex-column align-items-start">
                        <div class="d-flex align-center">
                          <h4  class="d-flex mr-2">Email:  </h4>
                          <input  type="text" class="form-control" formControlName="email"  placeholder="Enter the Email" name="email" (keyup)="onChange('email');getValidation()">
                        </div>
                        <span *ngIf="invalid.includes('email') && !this.form.get('email').value" style="margin-left : 72px" class="danger"><span class="fas fa-info-circle mr-2"></span>Email is required !</span>
                        <span *ngIf="invalid.includes('email') && this.form.get('email').value && getValidation()" style="margin-left : 72px" class="danger"><span class="fas fa-info-circle mr-2"></span>Please enter valid email address !</span>
                      </div>

                      <div class="d-flex mt-3" >
                        <button class=" btn btn-info mr-2" (click)="submit()">Edit</button>
                        <button class="btn btn-light" (click)="reset()">Cancel</button>
                      </div>

                    </div>
                  </div>

                  <a title="Edit" (click)="edit()"><i
                    class="far fa-edit size-logo"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>