import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifectionService } from '../../services/notifection.service';
import { UserServiceService } from "src/app/services/user-service.service";
import { Router } from "@angular/router";
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {

  specialityList;
  form;
  message: boolean = false;
  error_message: boolean = false;
  page = 1;
  totalItem = 0;
  limit = 10;
  search = '';
  datas: any;
  isDataTable: boolean = true;
  defaultAuthentication: string = 'Admin';

  constructor(public commonService: UserServiceService, private router: Router, private _toster: NotifectionService, private fb: FormBuilder, private spinner: NgxSpinnerService) {

  }

  ngOnInit(): void {
    this.form = this.fb.group({
      search: ['']
    });
    this.getList();
  }

  getList() {
    this.spinner.show();
    this.commonService.getProduct(this.page, this.limit, this.search).subscribe((res) => {
      this.spinner.hide();
      if (res['success']) {
        this.specialityList = res['data'];
     //   console.log(' this.specialityList-------------', this.specialityList);
        this.totalItem = res['total'];
        if (this.isDataTable) {
          this.loadExternalScript('assets/js/datatables.js');
          this.isDataTable = false;
        }
      }
    },
      (err) => {
        this._toster.showError("No Internet Connection!");
        this.spinner.show();
        this.specialityList = [];
      })
  }

  loadExternalScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  clear() {
    this.form.controls['search'].setValue('');
    this.search = '';
    this.getList();
  }

  delete(id) {
    if (confirm("Are you sure to delete the record ? ")) {
      this.spinner.show();
      this.commonService.deleteProduct(id).subscribe(res => {
        this.spinner.hide();
        if (res['success']) {
          this._toster.showSuccess(res['message']);
          this.getList();
        }
      }, err => {
        this._toster.showError(err.error.message);
        this.spinner.hide();
      })
    }
  }

  FadeOutSuccessMsg() {
    setTimeout(() => {
      this.message = false;
    }, 5000);
  }

  FadeOutErrorMsg() {
    setTimeout(() => {
      this.error_message = false;
    }, 5000);
  }



  getPgaeObj($event) {
    this.page = $event;
    this.getList();
  }

  searchData() {
    this.search = this.form.get('search').value;
    this.getList();
  }
}
