import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifectionService } from '../../services/notifection.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserServiceService } from 'src/app/services/user-service.service';

@Component({
  selector: 'app-admin-share',
  templateUrl: './admin-share.component.html',
  styleUrls: ['./admin-share.component.css']
})
export class AdminShareComponent implements OnInit {
  sum:any;
  validation:any;
  addProfileForm: FormGroup;
  allDatas: any;
  error_message: string;
  datas: any;
  message: string;

  constructor(
    private userService: UserServiceService,
    private fb: FormBuilder,
    private toster: NotifectionService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.getProfile();
  }

  initForm() {
    this.addProfileForm = this.fb.group({
      share: ['', Validators.required],
      _id: ['']
    });
  
  
  }

  getProfile() {
    this.spinner.show();
    this.userService.getAdminShare().subscribe(
      (res) => {
        //  console.log('===========,..>.=',res)
        this.allDatas = res['data'];

        this.addProfileForm.get('share').patchValue(this.allDatas[0]?.share);
        this.spinner.hide();
      },
      (err) => {
        this.toster.showError(err.error.message);
        this.spinner.hide();
        this.allDatas = [];
      }
    );
  }

  onProfileSubmit() {
    // Clear previous validation messages
    this.validation = [];
  
    // Check if the form is invalid
    if (this.addProfileForm.invalid) {
      this.error_message = 'Form is invalid. Please fill out all required fields.';
      return;
    }
  
    // Extract the share value and remove any percentage sign if present
    let share = this.addProfileForm.get('share').value;
    var value1 = parseFloat(share);
  if(value1){
    // Check if the share value is a number
    if (isNaN(value1)) {
      this.validation = 'Share must be a valid number.';
      return;
    }
  
    // Check if the share value is between 0 and 100
    if (value1 < 0 || value1 > 100) {
      this.validation = 'Share must be between 0% and 100%.';
      return;
    }
  }
    // Calculate the sum of all share values
    // Assuming this.allDatas contains all shares, including the current one being edited
    this.sum = this.allDatas.reduce((acc, data) => acc + parseFloat(data.share || 0), 0) - parseFloat(this.allDatas[0]?.share || 0) + value1;
  
    // Ensure the sum does not exceed 100%
    if (this.sum > 100) {
      this.validation = 'Total share percentage must not exceed 100%.';
      return;
    }
  
    // Prepare the object for submission
    let objective = {
      _id: this.allDatas[0]?._id,
      share: value1
    };
  
    // Show the loading spinner
    this.spinner.show();
  
    // Call the updateAdminShare method from the userService
    this.userService.updateAdminShare(objective).subscribe(
      (res) => {
        // Refresh the profile data
        this.getProfile();
  
        // Display success message
        this.toster.showSuccess(res.message);
  
        // Hide the loading spinner
        this.spinner.hide();
  
        // Reset the form and show success message
        this.datas = res.message;
        this.message = 'Profile updated successfully';
      },
      (err) => {
        // Display error message
        this.toster.showError(err.error.message);
  
        // Hide the loading spinner
        this.spinner.hide();
      }
    );
  }
  
  
}
