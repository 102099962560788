import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifectionService } from 'src/app/services/notifection.service';
import { UserServiceService } from '../../services/user-service.service';
@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {

  allUsers: any;
  addUserForm: FormGroup
  isDataTable: boolean = true;
  updateUser: FormGroup
  userId: any;
  defaultAuthentication: string = 'Admin';
  message: boolean = false;
  alert: any;
  datas: any;
  error_message: boolean = false;
  page = 1;
  limit = 10;
  search = '' ;
  totalItem=0;
  form;
  constructor(private userService: UserServiceService, private fb: FormBuilder, private _toster: NotifectionService, private spinner: NgxSpinnerService) { }

  loadExternalScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      search : ['']
    });
    this.getUsers();
  }


  getUsers() {
    this.spinner.show();

    this.userService.getContactDetails(this.page , this.limit , this.search).subscribe((res) => {
     // console.log(res)
      this.allUsers = res.data
      this.totalItem = res.total;
//console.log("===<<>>==", this.allUsers)
      this.spinner.hide();

      if (this.isDataTable) {
        this.loadExternalScript('assets/js/datatables.js');
        this.isDataTable = false;
      }
    },
      (err) => {
        this._toster.showError(err.error.message);
        this.spinner.hide();
        this.allUsers = [];
      }
    )
  };

  getPgaeObj($event)
  {
    this.page = $event;
    this.getUsers();
  }



  onDelete(id: any) {
    if (confirm("Are you sure to delete the record ? ")) {
      this.userService.deleteContactData(id).subscribe((res) => {
        
        this.getUsers();

      //  this.message = true;
        
        this._toster.showSuccess(res['message'])
      },
        (err) => {

          this._toster.showError(err.error.message);
        }
      )
    }
  }

  searchData()
  {
    this.search = this.form.get('search').value;
    this.getUsers();
  }

  clear()
  {
    this.form.controls['search'].setValue('');
    this.search = '';
    this.getUsers();
  }

}
