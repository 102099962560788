import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifectionService } from 'src/app/services/notifection.service';
import { Location } from '@angular/common';
import { UserServiceService } from "src/app/services/user-service.service";
@Component({
  selector: 'app-bank-details',
  templateUrl: './bank-details.component.html',
  styleUrls: ['./bank-details.component.css']
})
export class BankDetailsComponent implements OnInit {

  form: FormGroup;
  invalid: string[] = [];
  ID: string;
  routeSub: any;
  bankDetails:any;

  constructor(
      private spinner: NgxSpinnerService,
      private _toster: NotifectionService,
      private userService: UserServiceService,
      private router: Router,
      private fb: FormBuilder,
      private route: ActivatedRoute,
      private location: Location
  ) {
      this.routeSub = this.route.params.subscribe(params => {
          this.ID = params['id'];
      });
  }

  ngOnInit(): void {
      this.createForm();
      this.getWithdrawalDetails()
  }

  createForm() {
      this.form = this.fb.group({
          name: ['',],
          amount: ['',],
          holder_name:[''],
          security_number:[''],
          expiration_date:[''],
          card_number:[''],
          status: ['',],
          requestAmount: [''],

      });
  }

  clearValidationMessage(key: string) {
      const index = this.invalid.indexOf(key);
      if (index !== -1) {
          this.invalid.splice(index, 1);
      }
  }

  validateForm() {
      this.invalid = [];
      const controls = this.form.controls;
      for (const name in controls) {
          if (controls[name].invalid) {
              this.invalid.push(name);
          }
      }
      return this.invalid.length === 0;
  }

  cancel() {
      this.location.back();
  }

  edit() {
      if (this.validateForm()) {
          const formData = new FormData();
          this.spinner.show();

          formData.append('status', this.form.get('status')?.value);
          formData.append('id', this.ID);
          let updateObj={
              id:this.ID,
              status:this.form.get('status')?.value
          }
          this.userService.updateWithdrawal(updateObj).subscribe(
              res => {
                  this.spinner.hide();
                  this._toster.showSuccess(res.message);
                  this.location.back();
              },
              err => {
                  this.spinner.hide();
                  this._toster.showError(err.error.message);
              }
          );
      }
  }


  getWithdrawalDetails(){
   //   console.log('this.ID----------------',this.ID)
      this.userService.getBankdoc(this.ID).subscribe((res) => {
          this.spinner.hide();
         this.bankDetails= res.data;
       
        //   this.form.patchValue({
        //       name: res.data?.doctor_id?.username,
        //       card_number:res.data?.card_number,
        //       holder_name:res.data?.holder_name,
        //       amount:res.data.doctor_id?.totalIncome,
        //       expiration_date:res.data?.expiration_date,
        //       security_number:res.data?.security_number
        //   });
       
        //  this._toster.showSuccess(res.message);
      },
          (err) => {
              this.spinner.hide();
              this._toster.showError(err.error.message);
          })
  }

  ngOnDestroy() {
      this.routeSub.unsubscribe();
  }
}

