import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './includes/header/header.component';
import { SidebarComponent } from './includes/sidebar/sidebar.component';
import { FooterComponent } from './includes/footer/footer.component';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { UserManagementComponent } from './dashboard/user-management/user-management.component';
import { DashboardHomeComponent } from './dashboard-home/dashboard-home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthInterceptor } from './services/auth.interceptor';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ToastrComponentlessModule, ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { InputErrorModule } from './shared/input-error/input-error.module';
import { FilterPipe } from './services/filter.pipe';
import { DatePipe } from '@angular/common';
import { ContentManagementComponent } from './dashboard/content-management/content-management.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ProfileManagementComponent } from './dashboard/profile-management/profile-management.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ContactusComponent } from './dashboard/contactus/contactus.component';
import { UserServiceService } from './services/user-service.service';
import { DoctorManagementComponent } from './dashboard/doctor-management/doctor-management.component';
import { PatientManagementComponent } from './dashboard/patient-management/patient-management.component';
import { SignupManagementComponent } from './dashboard/signup-management/signup-management.component';

import { CmsComponent } from './dashboard/cms/cms.component';
import { CmsListingComponent } from './dashboard/cms-listing/cms-listing.component';
import { ChangePasswordComponent } from './dashboard/change-password/change-password.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SpecialityComponent } from './dashboard/speciality/speciality.component';
import { SpecialityListingComponent } from './dashboard/speciality-listing/speciality-listing.component';
import { BookingDetailsComponent } from './dashboard/booking-details/booking-details.component';
import { PaymentDetailsComponent } from './dashboard/payment-details/payment-details.component';
import { AdminShareComponent } from './dashboard/admin-share/admin-share.component';
import { WithdrawalComponent } from './dashboard/withdrawal/withdrawal.component';
import { WithdrawalEditComponent } from './dashboard/withdrawal-edit/withdrawal-edit.component';
import { BankDetailsComponent } from './dashboard/bank-details/bank-details.component';
import { UploadContentComponent } from './dashboard/upload-content/upload-content.component';
import { AddImageComponent } from './dashboard/add-image/add-image.component';
import { UpdateImageComponent } from './dashboard/update-image/update-image.component';
import { BookingFilterComponent } from './dashboard/booking-filter/booking-filter.component';
import { ProductComponent } from './dashboard/product/product.component';
import { ProductAddComponent } from './dashboard/product-add/product-add.component';
import { ProductUpdateComponent } from './dashboard/product-update/product-update.component';

// import {MatTableDataSource, MatTableModule} from '@angular/material/table';
// import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    DashboardComponent,
    UserManagementComponent,
    DashboardHomeComponent,
    FilterPipe,
    ContentManagementComponent,
    ProfileManagementComponent,
    ForgotPasswordComponent,
    ContactusComponent,
    DoctorManagementComponent,
    PatientManagementComponent,
    SignupManagementComponent,
    CmsComponent,
    CmsListingComponent,
    ChangePasswordComponent,
    SpecialityComponent,
    SpecialityListingComponent,
    BookingDetailsComponent,
    PaymentDetailsComponent,
    AdminShareComponent,
    WithdrawalComponent,
    WithdrawalEditComponent,
    BankDetailsComponent,
    UploadContentComponent,
    AddImageComponent,
    UpdateImageComponent,
    BookingFilterComponent,
    ProductComponent,
    ProductAddComponent,
    ProductUpdateComponent,
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2SearchPipeModule,
    ToastrModule.forRoot({
      timeOut: 2500,
      // positionClass: 'toast-top-right',
      preventDuplicates: true,
     
    }),
    NgxSpinnerModule,
    InputErrorModule,
    AngularEditorModule,
    NgxPaginationModule
  ],
  providers: [
    UserServiceService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    DatePipe
  ],
 
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule { }
