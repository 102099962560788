
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
	<p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<!-- Main Content -->
<div class="main-content">
	<section class="section">
		<div *ngIf="message" class="alert alert-success" role="alert" >
			<strong>{{datas}}</strong>
		</div>
		<div *ngIf="error_message"  class="alert alert-error" role="alert">
			<strong>{{datas}}</strong>
		</div>
		<!-- Tabs---Pills -->



		<!-- //////// ----Tabs -->


		<div class="section-header">
		</div>
		<div class="section-body">
			<div class="row">
				<div class="col-12">
					<div class="card">
						<div class="card-header d-Fle">
							<h5>Patient Lists</h5>
							<!-- <a href="" data-toggle="modal" data-target=".bd-example-modal-lg">Add Users</a> -->
						</div>
						<!-- <div class="card-body" *ngIf="!this.allUsers.length">
							<div class="d-flex justify-content-center">
								<img src="../../../assets/images/9264885.jpg" height="500px" width="500px" alt="">
							</div>
						</div> -->
						<div class="card-body" >
							<div *ngIf="!this.allUsers?.length" class="form-group search-box pull-right " style="top : 50px">
								<div [formGroup]="form" class="d-flex align-items-center">
									<input type="text" style="padding-right : 45px" name="search" formControlName="search" class="search form-control" placeholder="search by email , name" (keyup.enter)="searchData()">
									<span class="search-icon"  style="right: 29px;" (click)="clear()"><span class="fas fa-times-circle" style="font-size: 15px;"></span></span>
									<span class="search-icon" (click)="searchData()"><span class="fas fa-search"></span></span>
								</div>
							</div>
							<div class="table-responsive newPc" [ngStyle]="{display: this.allUsers?.length ?'inherit' : 'none'}">
								<div *ngIf="this.allUsers?.length" class="form-group search-box pull-right " style="top : 50px">
									<div [formGroup]="form" class="d-flex align-items-center">
										<input type="text" style="padding-right : 45px" name="search" formControlName="search" class="search form-control" placeholder="search by email , name" (keyup.enter)="searchData()">
										<span class="search-icon"  style="right: 29px;" (click)="clear()"><span class="fas fa-times-circle" style="font-size: 15px;"></span></span>
										<span class="search-icon" (click)="searchData()"><span class="fas fa-search"></span></span>
									</div>
								</div>
								<table  class="table table-striped table-hover" id="tableExport" style="width:100%;">
									<thead>
										<tr>
											<th>S. NO.</th>
											<th>Name</th>
											<th>Image</th>
											<th>Email</th>
											<th>Status</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let user of allUsers | paginate: { itemsPerPage: 10, currentPage: page , totalItems: totalItem}; let i = index; let c = count">
											<td>{{i+1 + (this.page > 1 ? (this.page-1) * 10 : 0)}}</td>
											<td>{{user.username}}</td>
											<td *ngIf="user.profileUrl.includes('https')"> <img src="https://api.dicebear.com/5.x/initials/svg?seed={{user.username}}" alt="IMG" class="table-logo"></td>
											<td *ngIf="!user.profileUrl.includes('https')"> <img src="https://docnowllc.com/images/{{user.profileUrl}}" alt="IMG" class="table-logo"></td>
											<td>{{user.email}}</td>
											<td><label class="switch">
												<input type="checkbox" [checked]="user.active"  (change)="toggleUserStatus($event , user , i)">
												<span class="slider round"></span>
											  </label>
											</td>
											<td class="action">
												<!-- <a href="javascript:void(0)"><i
														class="far fa-edit"></i></a> -->
												<a (click)="delete(user._id)" title="Delete" ><i
														class="far fa-trash-alt size-logo"></i></a>
												<!-- <a href="javascript:void(0)" 
													*ngIf="user.active==true"><i class="far fa-check-circle"
														style="color: #9de654"></i></a>
												<a href="javascript:void(0)" 
													*ngIf="user.active==false"><i class="far fa-times-circle"
														style="color: red"></i></a> -->
											</td>
										</tr>
									</tbody>
								</table>
								<pagination-controls  
								*ngIf="this.allUsers.length"
								(pageChange)="page = $event" 
								(pageChange)="getPgaeObj($event)"
								 screenReaderPaginationLabel="Pagination"
								screenReaderPageLabel="page"
								screenReaderCurrentLabel="You're on page"></pagination-controls>
								<p class="table-pagination-info">Showing {{1 + (this.page > 1 ? (this.page-1) * 10 : 0)}} to {{(10 + (this.page > 1 ? (this.page-1) * 10 : 0) ) > totalItem ? totalItem : 10 + (this.page > 1 ? (this.page-1) * 10 : 0) }} of {{totalItem}} entries</p>
							</div>
							<div class="d-flex justify-content-center" *ngIf="!this.allUsers.length">
								<img src="../../../assets/images/9264885.jpg" height="500px" width="500px" alt="">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</div>