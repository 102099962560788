import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { InfoService } from '../../services/info.service';
import { FormBuilder } from '@angular/forms';
import { NotifectionService } from '../../services/notifection.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserServiceService } from 'src/app/services/user-service.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  allDatas: any;
  baseImageUrl = 'https://docnowllc.com/images/'
  url;
  constructor(private _router: Router, private userService : UserServiceService ,  private fb: FormBuilder, private _toster: NotifectionService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getProfile();
    this.userService.getProfileLink().subscribe(res =>
      {
        res ? this.url = res : '';
      })
    
  }

  logOut() {
    if (confirm("Are you sure you want to Log Out?")) {
      localStorage.clear();
      this._router.navigate(['/login'])
    } else {
      return false; // Return false to prevent the default behavior (page refresh)
    }

  }

  getProfile() {
    this.userService.getProfileData().subscribe((res) => {
      this.url = res.data.profileUrl
      //console.log(this.allDatas);
    },
      (err) => {
        this.allDatas = [];
      }
    )
  }

  getImageUrl(url: string): string {
    if (!url?.includes('https')) {
      return this.baseImageUrl + url;
    }
    return url; // If the URL already contains 'https', return it as is
  }
}
