import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { NotifectionService } from "src/app/services/notifection.service";
import { UserServiceService } from "src/app/services/user-service.service";
import {Location} from '@angular/common';

@Component({
selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})

export class ChangePasswordComponent implements OnInit 
{
    form : any;
    invalid = [];
    newPasswordFlag =true;
    confirmPasswordFlag = true;
    url="https://api.dicebear.com/5.x/initials/svg?seed=Admin";
    baseImageUrl = 'https://docnowllc.com/images/';
    constructor(public fb : FormBuilder
        ,private _toster: NotifectionService ,
        private spinner: NgxSpinnerService,
        public router : Router,
        public userService : UserServiceService ,
        private _location: Location)
    {

    }

    ngOnInit(): void {
        this.form = this.fb.group({
            'oldPassword' : ['' , [Validators.required]],
            'newPassword' : ['' , [Validators.required]],
            'confirmPassword' : ['' , [Validators.required]],
        });
        this.getProfile()
        this.userService.getProfileLink().subscribe(res =>
            {
              res ? this.url = res : '';
            })
    }

    submit()
    {
        if(this.validateForm())
        {
            this.spinner.show();
            this.userService.changePassword(this.form.value).subscribe( res =>
            {
                this.spinner.hide();
                if(res.success)
                {
                    this.spinner.hide();
                    this._toster.showSuccess(res.message);
                    
                }
                else
                {
                    this.spinner.hide();
                    this._toster.showError(res.message);
                    
                }
            },
            err =>
            {
                this.spinner.hide();
                this._toster.showError(err.error.message);
                
            })
        }
        
    }

    validateForm()
    {
        const controls = this.form.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                this.invalid.push(name);
            }
        }
        return this.invalid.length ? false : true;
    }

    onChange(key)
    {
        if(this.invalid.includes(key))
        {
            this.invalid.splice(this.invalid.indexOf(key) , 1);
        }
    }

    cancel()
    {
        this._location.back();
    }

    changeConfirmPassField()
    {
        this.confirmPasswordFlag = !this.confirmPasswordFlag
    }

    changeNewPassField()
    {
        this.newPasswordFlag = !this.newPasswordFlag;
    }

    getProfile() {
        this.userService.getProfileData().subscribe((res) => {
          this.url = res.data.profileUrl
         
        },
          (err) => {
            
          }
        )
      }

    getImageUrl(url: string): string {
        if (!url.includes('https')) {
          return this.baseImageUrl + url;
        }
        return url; // If the URL already contains 'https', return it as is
      }
}