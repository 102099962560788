<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>



<!-- Main Content -->
<div class="main-content">
    <section class="section">
        <div *ngIf="message"  class="alert alert-success" role="alert">
            <strong>{{datas}}</strong>
        </div>
        <div *ngIf="error_message"  class="alert alert-error" role="alert">
            <strong>{{datas}}</strong>
        </div>
        <!-- Tabs---Pills -->



        <!-- //////// ----Tabs -->


        <div class="section-header">
        </div>
        <div class="section-body">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header d-Fle">
                            <h5>Contact Us</h5>
                        </div>
                        <div class="card-body">
                            <div *ngIf="!this.allUsers?.length" class="form-group search-box pull-right " style="top : 50px">
								<div [formGroup]="form" class="d-flex align-items-center">
                                    <input type="text" style="padding-right : 45px" name="search" formControlName="search" class="search form-control" placeholder="search by email" (keyup.enter)="searchData()">
                                    <span class="search-icon" style="right: 29px;" (click)="clear()"><span class="fas fa-times-circle" style="font-size: 15px;"></span></span>
                                    <span class="search-icon" (click)="searchData()"><span class="fas fa-search"></span></span>
                                </div>
							</div>
                            <div class="table-responsive newPc" [ngStyle]="{display: this.allUsers?.length ?'inherit' : 'none'}">
                                <div *ngIf="this.allUsers?.length" class="form-group search-box pull-right " style="top : 50px">
                                    <div [formGroup]="form" class="d-flex align-items-center">
                                        <input type="text" style="padding-right : 45px" name="search" formControlName="search" class="search form-control" placeholder="search by email" (keyup.enter)="searchData()">
                                        <span class="search-icon" style="right: 29px;" (click)="clear()"><span class="fas fa-times-circle" style="font-size: 15px;"></span></span>
                                        <span class="search-icon" (click)="searchData()"><span class="fas fa-search"></span></span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover" id="tableExport" style="width:100%;">
                                    <thead>
                                        <tr>
                                            <th>S. NO.</th>
                                            <th>Email</th>
                                            <th>Suggestion</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let user of allUsers | paginate: { itemsPerPage: 10, currentPage: page , totalItems: totalItem}; let i = index; let c = count">
                                            <td>{{i+1}}</td>
                                            <td>{{user?.email}}</td>
                                            <td id="suggestion"> <p >{{user?.content}}</p></td>
                                            <td class="action">
                                                <!-- <a [routerLink]="['/view-contact-us',user?._id]"><i
                                                    class="far fa-eye"></i></a> -->
                                                <a href="javascript:void(0)" title="Delete" (click)="onDelete(user._id)"><i
                                                        class="far fa-trash-alt logo-size"></i></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <pagination-controls  
                                *ngIf="this.allUsers.length"
								(pageChange)="page = $event" 
								(pageChange)="getPgaeObj($event)"
								 screenReaderPaginationLabel="Pagination"
								screenReaderPageLabel="page"
								screenReaderCurrentLabel="You're on page"></pagination-controls>
                            </div>
                            <div class="d-flex justify-content-center" *ngIf="!this.allUsers.length">
								<img src="../../../assets/images/9264885.jpg" height="500px" width="500px" alt="">
							</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>