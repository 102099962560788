<!-- Main Content -->
<style>
  .main-content {
    min-height: 550px !important;
  }
</style>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
	<p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<div class="main-content">
  <section class="section">

    <div class="section-body">
      <div class="">
        <h1 class="text-center mt-5">Welcome To Dashboard</h1>
        <div class="row">
          <div class="col-md-12 d-flex flex-wrap">
          <div class="col-md-4">
            <div class="card" [routerLink]="['/user-management']">
              <div class="card-body card-class clearfix  inner-addon right-addon">
                <!-- <img class="card-img-top" src="../../../assets/images/user_image.png" width="80" height="60" alt="Card image cap"> -->
                <i class="fas fa-users"></i>
              </div>
              <h5 class="text-center">Total Users</h5>

              <h4 class="text-center">{{data?.Patient + data?.Doctor}}</h4>
            </div>
          </div>

          <div class="col-md-4">
            <div class="card" [routerLink]="['/patient-management']">
              <div class="card-body clearfix card-class inner-addon right-addon">
                <!-- <img class="card-img-top" src="../../../assets/images/user_image.png" width="80" height="60" alt="Card image cap"> -->
                <i class="fas fa-users"></i>
              </div>
              <h5 class="text-center">Total Patients</h5>

              <h4 class="text-center">{{data?.Patient}}</h4>
            </div>
          </div>

          <div class="col-md-4">
            <div class="card" [routerLink]="['/doctor-management']">
              <div class="card-body clearfix card-class inner-addon right-addon">
                <!-- <img class="card-img-top" src="../../../assets/images/user_image.png" width="80" height="60" alt="Card image cap"> -->
                <i class="fas fa-users"></i>
              </div>
              <h5 class="text-center">Total Doctors</h5>

              <h4 class="text-center">{{data?.Doctor}}</h4>
            </div>
          </div>

        </div>
      </div>
      </div>
    </div>
  </section>
</div>