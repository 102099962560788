<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
	<p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<div class="main-content">
    <section class="section">
      <div class="section-header">
        <h1> Change Password </h1>
      </div>
      <div class="section-body">
        <div class="">
          <div class="">
            <div class="card mt-3">
              <div class="card-body">
                <div class="d-flex flex-column row align-center header-div">
                    <div>
                        <img class="img-logo" [src]="getImageUrl(url)" alt="IMG">
                    </div>

                    <div class="mt-4" >
                        <div [formGroup]="form" class="d-flex justify-content flex-column form-group col-md-6 m-auto">

                            <div class="mb-3 d-flex flex-column align-items-start">
                                <div class="d-flex justify-content-start">
                                    <label for="password">Old Password</label>
                                </div>
                                <div class="input-group mb-3">
                                    <input type="password" class="form-control" formControlName="oldPassword"  placeholder="Enter the valid password" name="password"  (keyup)="onChange('oldPassword')">
                                    
                                </div>		
                                <span *ngIf="invalid.includes('oldPassword')" class="danger"><span class="fas fa-info-circle mr-2"></span>Old password is required !</span>
                            </div>
                    
                            <div class="mb-3 d-flex flex-column align-items-start">
                                <div class="d-flex justify-content-start">
                                    <label  for="newPassword">New Password</label>
                                </div>
                                <div class="input-group mb-3">
                                    <input *ngIf="newPasswordFlag" style="padding-right : 32px" type="password" class="form-control"  formControlName="newPassword" placeholder="Enter the new password" name="newPassword" (keyup)="onChange('newPassword')">
                                    <input *ngIf="!newPasswordFlag" style="padding-right: 32px" type="text" class="form-control"  formControlName="newPassword" placeholder="Enter the new password" name="newPassword" (keyup)="onChange('newPassword')">
                                    <span *ngIf="newPasswordFlag" class="far fa-eye icon" (click)="changeNewPassField()"></span>
                                    <span *ngIf="!newPasswordFlag" class="far fa-eye-slash icon" (click)="changeNewPassField()"></span>
                                </div>		
                                <span *ngIf="invalid.includes('newPassword')" class="danger"><span class="fas fa-info-circle mr-2"></span>New password is required !</span>
                            </div>

                            <div class="mb-3 d-flex flex-column align-items-start">
                                <div class="d-flex justify-content-start">
                                    <label  for="confirmPassword">Confirm Password</label>
                                </div>
                                <div class="input-group mb-3">
                                    <input *ngIf="confirmPasswordFlag" style="padding-right: 32px" type="password" class="form-control" formControlName="confirmPassword" placeholder="Enter the confirm password" name="confirmPassword" (keyup)="onChange('confirmPassword')">
                                    <input *ngIf="!confirmPasswordFlag" style="padding-right: 32px" type="text" class="form-control" formControlName="confirmPassword" placeholder="Enter the confirm password" name="confirmPassword" (keyup)="onChange('confirmPassword')">
                                    <span *ngIf="confirmPasswordFlag" class="far fa-eye icon"(click)="changeConfirmPassField()"></span>
                                    <span *ngIf="!confirmPasswordFlag" class="far fa-eye-slash icon" (click)="changeConfirmPassField()"></span>
                                </div>		
                                <span *ngIf="invalid.includes('confirmPassword')" class="danger"><span class="fas fa-info-circle mr-2"></span>Confirm password is required !</span>
                            </div>
                        </div>
                        <div class="col-md-6 m-auto d-flex justify-content-start">
                            <button  class=" btn btn-info mr-2" (click)="submit()">Update</button>
                            <button class="btn btn-light" (click)="cancel()">Cancel</button>
                        </div>
                    </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>