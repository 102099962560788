<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
	<p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<div class="main-content">
	<section class="section">
		<div *ngIf="message" class="alert alert-success" role="alert">
			<strong>{{datas}}</strong>
		</div>
		<div *ngIf="error_message" class="alert alert-error" role="alert">
			<strong>{{datas}}</strong>
		</div>
		<!-- Tabs---Pills -->



		<!-- //////// ----Tabs -->


		<div class="section-header">
		</div>
		<div class="section-body">
			<div class="row">
				<div class="col-12">
					<div class="card mt-0">
						<div class="card-header d-Fle">
							<h5>Good Notes Image</h5>
							<a [routerLink]="['/add-image']">Add Image</a>
						</div>
						<div class="card-body">
							<!-- <div *ngIf="!this.specialityList?.length" class="form-group search-box pull-right "
								style="top : 50px">
								<div [formGroup]="form" class="d-flex align-items-center">
									<input type="text" style="padding-right : 45px" name="search"
										formControlName="search" class="search form-control"
										placeholder="Search by Content" (keyup.enter)="searchData()">
									<span class="search-icon" style="right: 29px;" (click)="clear()"><span
											class="fas fa-times-circle" style="font-size: 15px;"></span></span>
									<span class="search-icon" (click)="searchData()"><span
											class="fas fa-search"></span></span>
								</div>
							</div> -->
							<div class="table-responsive newPc"
								[ngStyle]="{display: this.specialityList?.length ?'inherit' : 'none'}">
								<div *ngIf="this.specialityList?.length" class="form-group search-box pull-right "
									style="top : 50px">
									<!-- <div [formGroup]="form" class="d-flex align-items-center">
										<input type="text" style="padding-right : 45px" name="search"
											formControlName="search" class="search form-control"
											placeholder="Search by Content" (keyup.enter)="searchData()">
										<span class="search-icon" style="right: 29px;" (click)="clear()"><span
												class="fas fa-times-circle" style="font-size: 15px;"></span></span>
										<span class="search-icon" (click)="searchData()"><span
												class="fas fa-search"></span></span>
									</div> -->
								</div>
								<table class="table table-striped table-hover" id="tableExport" style="width:100%;">
									<thead>
										<tr>
											<th>S. NO.</th>
											<th>Image</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										<tr
											*ngFor="let element of specialityList | paginate: { itemsPerPage: 10, currentPage: page , totalItems: totalItem}; let i = index; let c = count">
											<td>{{i+1 + (this.page > 1 ? (this.page-1) * 10 : 0)}}</td>
											<td><img [src]="element?.images[0]?.filename" alt="IMG" class="table-logo"></td>
											
											<td class="action">
												<a [routerLink]="['/update-images', element?._id]"><i
														class="far fa-edit"></i></a>
												<a (click)="delete(element._id)" title="Delete"><i
														class="far fa-trash-alt size-logo"></i></a>
											</td>
										</tr>
									</tbody>
								</table>
								<pagination-controls *ngIf="this.specialityList?.length" (pageChange)="page = $event"
									(pageChange)="getPgaeObj($event)" screenReaderPaginationLabel="Pagination"
									screenReaderPageLabel="page"
									screenReaderCurrentLabel="You're on page"></pagination-controls>
								<p class="table-pagination-info">Showing {{1 + (this.page > 1 ? (this.page-1) * 10 :
									0)}} to {{(10 + (this.page > 1 ? (this.page-1) * 10 : 0) ) > totalItem ? totalItem :
									10 + (this.page > 1 ? (this.page-1) * 10 : 0) }} of {{totalItem}} entries</p>
							</div>
							<div class="d-flex justify-content-center" *ngIf="!this.specialityList?.length">
								<img src="../../../assets/images/9264885.jpg" height="500px" width="500px" alt="">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</div>