<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
  </ngx-spinner>
  
  <div class="main-content">
    <section class="section">
      <div class="section-header">
        <!-- <h1> Admin Profile </h1> -->
      </div>
      <div class="section-body">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <form [formGroup]="addProfileForm" (ngSubmit)="onProfileSubmit()" enctype="multipart/form-data">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label>Share (%)<span style="color: red;">*</span></label>
                       <input type="number" class="form-control" formControlName="share">
                        <div *ngIf="validation"><span style="color: red">{{validation}}</span>
                        </div>
                        <div *ngIf="addProfileForm.get('share').invalid && addProfileForm.get('share').touched" class="text-danger">
                          Share is required.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-2 col-md-12 col-sm-12 mt-4">
                      <input type="submit" value="Update" class="modal-btn" [disabled]="addProfileForm.invalid">
                    </div>
                  </div>
                  
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  