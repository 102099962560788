<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
	<p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<div class="main-content">
	<div *ngIf="!this.isEdit" class="title m-auto d-flex justify-content flex-column col-md-8 mb-3">
		<h3 >Add Speciality </h3>
        <button class="form-control" (click)="back()" style="margin-left: 91%;" class="btn btn-primary " type="submit">
            Go Back
        </button>
	</div>
    <div *ngIf="this.isEdit" class="title m-auto d-flex justify-content flex-column col-md-8 mb-3">
		<h3 >Edit Speciality</h3>
        <button class="form-control" (click)="back()" style="margin-left: 91%;" class="btn btn-primary " type="submit">
            Go Back
        </button>
	</div>

    <div class="mb-5 mt-5" >
        <div [formGroup]="form" class="d-flex justify-content flex-column form-group col-md-8 m-auto">
            <div class="mb-3 d-flex flex-column align-items-start">
                <div>
                    <label for="name">Speciality Name :</label>
                </div>
                <div class="input-group mb-3">
                    <input type="text" class="form-control" onkeypress="return event.keyCode !== 32" formControlName="name"  placeholder="Enter the speciality name" name="name" (keyup)="clearValidationMessage('name')">
                </div>	
                <span *ngIf="this.invalid?.includes('name')" class="danger"><span class="fas fa-info-circle mr-2"></span>Speciality name is required !</span>
                <span *ngIf="form.get('name').errors?.hasSpaces" class="danger"><span class="fas fa-info-circle mr-2"></span>Speciality name should not contain spaces!</span>
                <span *ngIf="this.form.get('name').value?.length > 30"  class="danger"><span class="fas fa-info-circle mr-2"></span>Speciality name should be less than 30 characters!</span>	
            </div>
            <div class="mb-3 d-flex flex-column align-items-start">
                <div>
                    <label for="name">Speciality Image :</label>
                </div>
                <div class="input-group mb-3">
                    <input type="file" class="form-control" accept="image/jpeg,image/png" formControlName="image" (mouseup)="clearValidationMessage('image')" (change)="onFileChange($event)" placeholder="Enter the speciality image" name="image" >
                </div>	
                <div *ngIf="image">
                    <img src="https://docnowllc.com/images/{{image}}" 
                        style="width: 100px;height: 100px;" alt="IMG" class="table-logo">
                </div>
                <div *ngIf="!this.isEdit">
                <span *ngIf="this.invalid?.includes('image')" class="danger"><span class="fas fa-info-circle mr-2"></span>Speciality image is required !</span>
            </div>
            </div>
           
        </div>
        <div class="col-md-8 m-auto">
            <button *ngIf="!this.isEdit" class=" btn btn-info mr-2" (click)="submit()">Submit</button>
            <button *ngIf="this.isEdit" class=" btn btn-info mr-2" (click)="edit()">Edit</button>
            <button class="btn btn-light" (click)="cancel()">Cancel</button>
        </div>
    </div>

</div>