import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { api } from './api';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserServiceService {

  profileImageLink = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) { }

  getProfileData(): Observable<any> {
    return this.http.get(`${api.getProfile}?page=${null}&limit=${null}&search=${null}`);
  }

  getUsersData(page, limit, search = ''): Observable<any> {
    return this.http.get(`${api.getAllUsers}?page=${page}&limit=${limit}&search=${search}`);
  }

  getContactDetails(page, limit, search = ''): Observable<any> {
    return this.http.get(`${api.getContactUsDetails}?page=${page}&limit=${limit}&search=${search}`);
  }

  deleteContactData(id) {
    return this.http.delete(`${api.deleteContactUs}/${id}`);
  }

  setstatus(data) {
    return this.http.post(`${api.setStatus}`, data);
  }

  getDoctorsData(page, limit, search = '') {
    return this.http.get(`${api.getDoctors}?page=${page}&limit=${limit}&search=${search}`);
  }

  getPatientData(page, limit, search = '') {
    return this.http.get(`${api.getPatient}?page=${page}&limit=${limit}&search=${search}`);
  }

  getEnrolledDoctors() {
    return this.http.get(`${api.getEnrolledDoctors}?page=${null}&limit=${null}&search=${null}`);
  }

  downloadDocs(id) {
    return this.http.get(`${api.downloadDocs}/${id}`, { observe: "response", responseType: "blob" });
  }

  getCount() {
    return this.http.get(api.getCount);
  }

  editProfile(payload): Observable<any> {
    return this.http.post(`${api.adminUpdateProfile}`, payload);
  }

  deleteUser(id): Observable<any> {
    return this.http.delete(`${api.deleteUser}/${id}`);
  }

  addPolicy(payload): Observable<any> {
    return this.http.post(`${api.addPolicy}`, payload);
  }

  getPolicy(language): Observable<any> {
    return this.http.get(`${api.getPolicy}/${language}`);
  }

  changePassword(payload): Observable<any> {
    return this.http.post(`${api.adminChangePassword}`, payload);
  }

  uploadProfileImage(file): Observable<any> {
    const formData: any = new FormData();

    formData.append('file', file);

    return this.http.post(api.uploadProfileImage, formData);
  }

  sendProfileLink(val) {
    this.profileImageLink.next(val);
  }

  getProfileLink(): Observable<any> {
    return this.profileImageLink.asObservable();
  }










  // FOR BOOKING 

  getBookingData(page, limit, search = '') {
    return this.http.get(`${api.getBooking}?page=${page}&limit=${limit}&search=${search}`);
  }
  getBookingFilter(page, limit, search = '') {
    return this.http.get(`${api.getBookingFilter}?page=${page}&limit=${limit}&search=${search}`);
  }


  // for payment 
  getPaymentDetails(page, limit, search = '') {
    return this.http.get(`${api.getPayment}?page=${page}&limit=${limit}&search=${search}`);
  }

  getAdminShare() {
    return this.http.get(`${api.getAdminShare}`);
  }


  updateAdminShare(objData: any) {
    //console.log('objData--',objData);
    return this.http.post<any>(`${api.updateAdminShare}`, objData);
  }

  getWithdrawalReq(page, limit, search = '') {
    return this.http.get(`${api.getWithdrawalReq}?page=${page}&limit=${limit}&search=${search}`);
  }

  updateWithdrawal(objData: any) {
    //console.log('objData--',objData);
    return this.http.post<any>(`${api.updateWithdrawal}`, objData);
  }
  getWithdrawalReqById(id): Observable<any> {
    return this.http.get(`${api.getWithdrawalReqById}/${id}`);
  }


  getBankdoc(id): Observable<any> {
    return this.http.get(`${api.getBankdoc}/${id}`);
  }


  addImage(objData: any) {
    //console.log('objData--',objData);
    return this.http.post<any>(`${api.addImage}`, objData);
  }
  getImageById(id): Observable<any> {
    return this.http.get(`${api.getImageById}/${id}`);
  }
  getData(page, limit, search = '') {
    return this.http.get(`${api.getImage}?page=${page}&limit=${limit}&search=${search}`);

  }

  deleteImage(id) {
    return this.http.delete(`${api.deleteImage}/${id}`);
  }

  updateImage(objData: any) {
    //console.log('objData--',objData);
    return this.http.post<any>(`${api.updateImage}`, objData);
  }
  deleteArrayImage(objdata: any) {
    return this.http.post<any>(`${api.deleteArrayImage}`, objdata);
  }


  // product

  addProduct(objData: any) {
    //console.log('objData--',objData);
    return this.http.post<any>(`${api.addProduct}`, objData);
  }
  getProductById(id): Observable<any> {
    return this.http.get(`${api.getProductById}/${id}`);
  }
  getProduct(page, limit, search = '') {
    return this.http.get(`${api.getProduct}?page=${page}&limit=${limit}&search=${search}`);

  }

  deleteProduct(id) {
    return this.http.delete(`${api.deleteProduct}/${id}`);
  }

  updateProduct(objData: any) {
    //console.log('objData--',objData);
    return this.http.post<any>(`${api.updateProduct}`, objData);
  }

}