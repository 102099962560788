import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import * as moment from 'moment';
import {api} from './api';
import {Observable} from 'rxjs';
const jwt = new JwtHelperService();

class DecodedToken {
    exp: number = 30;
    username: string = '';
}


@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private decodedToken;

    constructor(private _http: HttpClient, private router: Router) {
        this.decodedToken = JSON.parse(localStorage.getItem('hr_meta')) || new DecodedToken();
    }

    saveToken(token: string) {
        this.decodedToken = jwt.decodeToken(token);

        localStorage.setItem('hr_auth', token);
       // console.log('token---',token)
        localStorage.setItem('hr_meta', JSON.stringify(this.decodedToken));

        return token;

    }


    private getExpiration() {
        return moment.unix(this.decodedToken.exp);
    }

   

    login(objData: any): Observable<any> {
        return this._http.post(`${api.adminLogin}`, objData)
    }


    public getAuthToken(): string {
        return localStorage.getItem('hr_auth');
    }


    public isAuthenticated(): boolean {
        return moment().isBefore(this.getExpiration());
    }
}
