<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
	<p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<!-- Main Content -->
<div class="main-content">
	<section class="section">
		<div *ngIf="message" class="alert alert-success" role="alert">
			<strong>{{datas}}</strong>
		</div>
		<div *ngIf="error_message"  class="alert alert-error" role="alert">
			<strong>{{datas}}</strong>
		</div>
		<!-- Tabs---Pills -->



		<!-- //////// ----Tabs -->
		


		<div class="section-header">
		</div>
		<div class="section-body">
			<div class="row">
				<div class="col-12">
					<div class="card">
						<div class="card-header d-Fle">
							<h5>Post Lists</h5>
							<a [routerLink]="['/cms/add']">Add Post</a>
						</div>

						<div class="card-body" *ngIf="!this.cmsList?.length" >
							<div  class="form-group search-box pull-right ">
								<div [formGroup]="form" class="d-flex align-items-center">
									<input type="text" style="padding-right : 45px" name="search" formControlName="search" class="search form-control" placeholder="search by title" (keyup.enter)="searchData()">
									<span class="search-icon" style="right: 29px;" (click)="clear()"><span class="fas fa-times-circle" style="font-size: 15px;"></span></span>
									<span class="search-icon" (click)="searchData()"><span class="fas fa-search"></span></span>
								</div>
							</div>
							<div class="d-flex justify-content-center" >
								<img src="../../../assets/images/9264885.jpg" height="500px" width="500px" alt="">
							</div>
						</div>

						<div class="table-responsive newPc" [ngStyle]="{display: this.cmsList?.length ?'inherit' : 'none'}">
							<div *ngIf="this.cmsList?.length" class="form-group search-box pull-right ">
								<div [formGroup]="form" class="d-flex align-items-center">
									<input type="text" style="padding-right : 45px" name="search" formControlName="search" class="search form-control" placeholder="search by title" (keyup.enter)="searchData()">
									<span class="search-icon" style="right: 29px;" (click)="clear()"><span class="fas fa-times-circle" style="font-size: 15px;"></span></span>
									<span class="search-icon" (click)="searchData()"><span class="fas fa-search"></span></span>
								</div>
							</div>
						
							<div class="card-body">
									<table class="table table-striped table-hover" id="tableExport" style="width:100%;">
										<thead>
											<tr>
												<th>S. NO.</th>
												<th>Title</th>
												<th>Description</th>
												<th>Language</th>
												<th>Action</th>
											</tr>
										</thead>
										<tbody>
											<tr *ngFor="let item of cmsList | paginate: { itemsPerPage: 10, currentPage: page , totalItems: totalItem}; let i = index; let c = count">
												<td>{{i+1}}</td>
												<td>{{item.title}}</td>
												<td>{{item.description}}</td>
												<td>{{lang[item.lang]}}</td>
												<td class="action">
													<a title="Edit" (click)="edit(i+1 , item)"><i
														class="far fa-edit size-logo"></i></a>
													<a  (click)="deletePost(item._id)" title="Delete"><i
															class="far fa-trash-alt size-logo"></i></a>
												</td>
											</tr>
										</tbody>
									</table>
									<pagination-controls  
									*ngIf="this.cmsList.length"
									(pageChange)="page = $event" 
									(pageChange)="getPgaeObj($event)"
									screenReaderPaginationLabel="Pagination"
									screenReaderPageLabel="page"
									screenReaderCurrentLabel="You're on page"></pagination-controls>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</div>