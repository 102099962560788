<div class="main-sidebar sidebar-style-2" id="sidebar">
  <aside id="sidebar-wrapper">
    <div class="sidebar-brand">
      <a [routerLink]="['/dashboard']">
        <img alt="image" src="assets/images/dr.png" class="header-logo" />
        <div class="logo-name">Admin Panel</div>
      </a>
    </div>
    <ul class="sidebar-menu">
      <li class="menu-header"><a><span class="move-left">Admin</span></a></li>

      <li class="dropdown" routerLinkActive="active">
        <a [routerLink]="['/dashboard']" class="nav-link  "><i class="fas fa-home"></i><span>Dashboard</span></a>
      </li>

      <li class="dropdown" routerLinkActive="active">
        <a [routerLink]="['/user-management']" class="nav-link  "><i class="fas fa-users"></i><span>All User</span></a>
      </li>

      <li class="dropdown" routerLinkActive="active">
        <a [routerLink]="['/doctor-management']" class="nav-link  "><i class="fas fa-users"></i><span>Doctor
            Management</span></a>
      </li>

      <li class="dropdown" routerLinkActive="active">
        <a [routerLink]="['/patient-management']" class="nav-link  "><i class="fas fa-users"></i><span>Patient
            Management</span></a>
      </li>
      <li class="dropdown" routerLinkActive="active">
        <a [routerLink]="['/product']" class="nav-link  "><i class="fas fa-list"></i><span>Product Management
          </span></a>
      </li>

    <li class="dropdown">
        <a class="nav-link" data-toggle="collapse" href="#paymentDetailsMenu">
          <i class="fas fa-address-book"></i><span>Transaction-Management
            <i class="fas fa-caret-down dropdown-icon"></i>
          </span></a>

        <div class="collapse" id="paymentDetailsMenu">
          <ul class="sidebar-menu">

            <li class="dropdown" routerLinkActive="active">
              <a [routerLink]="['/admin-share']" class="nav-link  "><i class="fas fa-percent"></i><span>Share
                </span></a>

            </li>
           
            <li class="dropdown" routerLinkActive="active">
              <a [routerLink]="['/booking-details']" class="nav-link  "><i class="fas fa-list"></i><span>Booking Detail
                </span></a>
            </li>
            <!-- <li class="dropdown" routerLinkActive="active">
              <a [routerLink]="['/withdrawal-req']" class="nav-link "><i class="fas fa-money-bill-wave"></i><span>Withdrawal Request
                </span></a>
            </li> -->
          </ul>
        </div>
      </li> 






      <!-- <li class="dropdown" routerLinkActive="active">
        <a [routerLink]="['/booking-details']" class="nav-link  "><i class="fas fa-list"></i><span>Booking Detail
          </span></a>
      </li> -->





      
    
      <li class="dropdown" routerLinkActive="active">
        <a [routerLink]="['/upload-document']" class="nav-link "><i class="fas fa-images"></i><span>Upload Image
          </span></a>
      </li>
      <li class="dropdown" routerLinkActive="active">
        <a [routerLink]="['/contact-us']" class="nav-link "><i class="fas fa-list"></i><span>Contact Us
          </span></a>
      </li>

      <li class="dropdown" routerLinkActive="active">
        <a [routerLink]="['/cms-listing']" class="nav-link "><i class="fas fa-video" aria-hidden="true"></i><span>Video
            Management
          </span></a>
      </li>

      <li class="dropdown" routerLinkActive="active">
        <a [routerLink]="['/privacy-policy']" class="nav-link "><i class="fas fa-user-shield"
            aria-hidden="true"></i><span>Privacy Policy
          </span></a>
      </li>

      <li class="dropdown" routerLinkActive="active">
        <a [routerLink]="['/speciality-list']" class="nav-link "><i class="fas fa fa-stethoscope"
            aria-hidden="true"></i><span>Speciality
          </span></a>
      </li>

      <li class="dropdown" routerLinkActive="active">
        <a href="" (click)="logOut()" class="nav-link">
          <i class="fas fa-sign-out-alt"></i> <span>Logout</span>
        </a>
      </li>


    </ul>
  </aside>
</div>