<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
	<p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<!-- Main Content -->
<div class="main-content">
	<section class="section">
		<div *ngIf="message" class="alert alert-success" role="alert">
			<strong>{{datas}}</strong>
		</div>
		<div *ngIf="error_message"  class="alert alert-error" role="alert">
			<strong>{{datas}}</strong>
		</div>
		<!-- Tabs---Pills -->



		<!-- //////// ----Tabs -->


		<div class="section-header">
		</div>
		<div class="section-body">
			<div class="row">
				<div class="col-12">
					<div class="card">
						<div class="card-header d-Fle">
							<h5>User Lists</h5>
							<!-- <a href="" data-toggle="modal" data-target=".bd-example-modal-lg">Add Users</a> -->
						</div>
						<div class="card-body">
							<div class="table-responsive newPc">
								<table class="table table-striped table-hover" id="tableExport" style="width:100%;">
									<thead>
										<tr>
											<th>S. NO.</th>
											<th>Name</th>
											<th>Image</th>
											<th>Email</th>
											<th>Documents</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let user of allUsers; let i = index; let c = count">
											<td>{{i+1}}</td>
											<td>{{user.username}}</td>
											<td><img src="http://localhost:3000/images/{{user.profileUrl}}" alt="IMG" class="table-logo"></td>
											<td>{{user.email}}</td>
                                            <td><a href="javascript:void(0)" (click)="DownloadFile(user)" title="Documents"><i class="fa fa-file size-logo" style="color: #000000"></i></a></td>
											<td class="action">
												<!-- <a href="javascript:void(0)"><i
														class="far fa-edit"></i></a> -->
												<!-- <a href="javascript:void(0)" ><i
														class="far fa-trash-alt size-logo"></i></a> -->
												<a href="javascript:void(0)" title="Approve"><i class="far fa-check-circle size-logo" style="color: #9de654"></i></a>
												<a href="javascript:void(0)" title="Reject"><i class="far fa-times-circle size-logo" style="color: red"></i></a>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	
</div>