import { Component, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from "src/app/services/common.service";
import { NotifectionService } from 'src/app/services/notifection.service';
import { Location } from '@angular/common'

@Component({
    selector: 'app-speciality',
    templateUrl: './speciality.component.html',
    styleUrls: ['./speciality.component.css']
})

export class SpecialityComponent implements OnInit {
    form;
    invalid = [];
    ID;
    image:any;
    routeSub: any;
    logo:any;
    isEdit: Boolean = false;

    constructor(private spinner: NgxSpinnerService,
        private _toster: NotifectionService,
        public commonService: CommonService,
        public router: Router,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private location: Location) {
        this.routeSub = this.route.params.subscribe(params => {
            this.ID = params['id'];
        });
    }
    

    ngOnInit(): void {
      
        if (this.router.url.includes('edit')) {
            this.isEdit = true;
            this.setFormValues();
        }
        this.createForm();
    }

    createForm() {
      //  console.log(this.isEdit)
        this.form = this.fb.group({ name: ['', Validators.required], image: ['', this.isEdit ? null : Validators.required] },);
    }

    setFormValues() {
        this.spinner.show();
        this.commonService.getSpecialityName(this.ID).subscribe((res) => {
            this.spinner.hide();
            this.form.patchValue({
                name: res.data.name,
            });
            this.image = res.data?.image,
         //   console.log('image', this.image )
            this._toster.showSuccess(res.message);
        },
            (err) => {
                this.spinner.show();
                this._toster.showError(err.error.message);
            })
    }

    clearValidationMessage(key) {

 //       console.log('====<<key>>===',key)
        if (this.invalid.includes(key)) {
            this.invalid.splice(this.invalid.indexOf(key), 1);
        }
    }

  
    validateForm() {
        const controls = this.form.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                this.invalid.push(name);
            }
        }

        return this.invalid.length ? false : true;
    }

    cancel() {
        this.location.back();
    }
    back() {
    
        this.location.back(); // 
      }
    edit() {
        if (this.validateForm()) {
         //   const payload = this.form.value;
            let formData = new FormData();
            this.spinner.show();
            formData.append('name', this.form.get('name').value);
            formData.append('image', this.logo);
            this.commonService.editSpeciality(this.ID, formData).subscribe(res => {
                this.spinner.hide();
                this._toster.showSuccess(res.message);
                this.location.back();
            }, err => {
                this.spinner.hide();
                this._toster.showError(err.error.message);
            });
        }
    }

    onFileChange(event) {
        this.logo = event.target.files && event.target.files[0];
      
    
      };

    
    submit() {
       // console.log('==<<>==this.logo==',this.logo);
        let formData = new FormData();
        if (this.validateForm()) {
         //   const payload = this.form.value;
           
            formData.append('name', this.form.get('name').value);
            formData.append('image', this.logo);
           // console.log('==<<addObj>>==',addObj);
            this.spinner.show();
            this.commonService.addSpeciality(formData).subscribe(res => {
                this.spinner.hide();
                this._toster.showSuccess(res.message);
                this.location.back();
            }, err => {
                this.spinner.hide();
                this._toster.showError(err.error.message);
            });
        }
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe();
    }















}