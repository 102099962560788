import { environment } from "src/environments/environment";
const LOCALURL = environment.apilocalUlr;


export const api = {
    adminLogin: `${LOCALURL}/login`,
    adminChangePassword: `${LOCALURL}/change-password`,
    adminUpdateProfile: `${LOCALURL}/user/update-profile`,
    uploadProfileImage: `${LOCALURL}/user/upload-image`,


    getProfile: `${LOCALURL}/user/get-profile`,
    getAllUsers: `${LOCALURL}/user/get-all`,
    setStatus: `${LOCALURL}/user/set-status`,
    getDoctors: `${LOCALURL}/user/get-doctors`,
    getPatient: `${LOCALURL}/user/get-patient`,
    getEnrolledDoctors: `${LOCALURL}/user/active-doctors`,
    downloadDocs: `${LOCALURL}/user/export-doc`,
    getCount: `${LOCALURL}/user/count`,
    deleteUser: `${LOCALURL}/user/delete`,

    getPost: `${LOCALURL}/cms/get-post`,
    addPost: `${LOCALURL}/cms/add-post`,
    getAllPost: `${LOCALURL}/cms/getAll`,
    editPost: `${LOCALURL}/cms/edit`,
    deletePost: `${LOCALURL}/cms/delete`,

    getContactUsDetails: `${LOCALURL}/contact-us/get-all`,
    deleteContactUs: `${LOCALURL}/contact-us/delete`,

    addPolicy: `${LOCALURL}/policies/add`,
    getPolicy: `${LOCALURL}/policies/get`,

    deleteSpeciality: `${LOCALURL}/speciality/delete`,
    getSpecialityList: `${LOCALURL}/speciality/fetch-list`,
    addSpeciality: `${LOCALURL}/speciality/add`,
    editSpeciality: `${LOCALURL}/speciality/edit`,
    getSpeciality: `${LOCALURL}/speciality/get`,

    getBookingFilter:`${LOCALURL}/booking/get-booking-admin`,
    getBooking: `${LOCALURL}/booking/get-booking-details`,
    getPayment: `${LOCALURL}/payment/get-payment-details`,
    getAdminShare: `${LOCALURL}/payment/get-admin-share`,
    updateAdminShare: `${LOCALURL}/payment/update-admin-share`,
    getWithdrawalReq: `${LOCALURL}/payment/get-withdrawalReq`,
    updateWithdrawal: `${LOCALURL}/payment/update-withdrawal`,
    getWithdrawalReqById: `${LOCALURL}/payment/get-withdrawalReq`,
    getBankdoc: `${LOCALURL}/payment/get-bank-doc`,

    addImage: `${LOCALURL}/content/add-images`,
    getImage: `${LOCALURL}/content/get-data`,
    getImageById: `${LOCALURL}/content/get-image`,
    deleteImage: `${LOCALURL}/content/delete-image`,
    updateImage: `${LOCALURL}/content/update-image`,
    deleteArrayImage: `${LOCALURL}/content/delete-array-image`,


    // product

    addProduct:`${LOCALURL}/product/add-product`,
    getProduct: `${LOCALURL}/product/get-data-product`,
    getProductById: `${LOCALURL}/product/get-Product`,
    deleteProduct: `${LOCALURL}/product/delete-Product`,
    updateProduct: `${LOCALURL}/product/update-Product`,
   
}