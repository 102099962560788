<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<div class="main-content">
    <div class="title m-auto d-flex justify-content flex-column col-md-8 mb-3">
        <h3>Edit Details</h3>
        <button class="form-control btn btn-primary" (click)="cancel()" style="margin-left: 83%; width: 14%;"
            type="button">
            Go Back
        </button>
    </div>

    <div class="mb-5 mt-5">
        <div [formGroup]="form" class="d-flex justify-content flex-column form-group col-md-8 m-auto">
            <div class="d-flex flex-column align-items-start">
                <label for="name">Title</label>
                <div class="input-group">
                    <input type="text" onkeypress="return event.keyCode !== 32"class="form-control" formControlName="title">
                </div>
                <span *ngIf="this.invalid?.includes('title')" class="danger"><span
                        class="fas fa-info-circle mr-2"></span>Title is required !</span>

            </div>
            <div class="mb-3 d-flex flex-column align-items-start">
                <div>
                    <label for="images">Image</label>
                </div>
                <div class="input-group ">
                    <input type="file" class="form-control" multiple="true" accept="image/jpeg,image/png"
                        formControlName="image" (mouseup)="clearValidationMessage('image')"
                        (change)="onFileChange($event)" placeholder="Enter the speciality image" name="image">
                </div>
                <div *ngIf="blogImage">
                    <img [src]="blogImage" 
                        style="width: 100px;height: 80px;" alt="IMG" class="table-logo">
                </div>
              
            </div>



            <div class="mb-3 d-flex flex-column align-items-start">
                <label for="name">Link</label>
                <div class="input-group ">
                    <input type="text" onkeypress="return event.keyCode !== 32" class="form-control" formControlName="link">
                </div>
                <span *ngIf="invalid.includes('link') && form.get('link').errors?.required" class="danger">
                    <span class="fas fa-info-circle mr-2"></span> Link is required!
                  </span>
                  <span *ngIf="invalid.includes('link') && form.get('link').errors?.pattern" class="danger">
                    <span class="fas fa-info-circle mr-2"></span> Invalid link!
                  </span>

            </div> <div class="mb-3 d-flex flex-column align-items-start">
                <label for="name">Description</label>
                <div class="input-group ">
                    <textarea type="text" onkeypress="return event.keyCode !== 32" class="form-control" formControlName="description"></textarea>
                </div>
                <span *ngIf="this.invalid?.includes('description')" class="danger"><span
                        class="fas fa-info-circle mr-2"></span>Description is required !</span>

            </div>
        </div>
    </div>
    <div class="col-md-8 m-auto" style="margin-top:-4% !important">
        <button class="btn btn-info mr-2" (click)="edit()">Edit</button>
        <button class="btn btn-light" (click)="cancel()">Cancel</button>
    </div>
</div>