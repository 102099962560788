<div id="app">
    <div class="main-wrapper main-wrapper-1">
      <app-header></app-header>
  
      <app-sidebar></app-sidebar>
     
     <router-outlet></router-outlet>
      
  
      <app-footer></app-footer>
    </div>
  </div>