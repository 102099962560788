import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifectionService } from 'src/app/services/notifection.service';
import { Location } from '@angular/common';
import { UserServiceService } from "src/app/services/user-service.service";

@Component({
  selector: 'app-product-add',
  templateUrl: './product-add.component.html',
  styleUrls: ['./product-add.component.css']
})
export class ProductAddComponent implements OnInit, OnDestroy {
  logo: any;
  form: FormGroup;
  invalid: string[] = [];
  ID: string;
  routeSub: any;
  urlPattern = '(https?:\/\/(?:www\.|(?!www))[^s\.]+.[^\s]{2,}|www\.[^\s]+.[^\s]{2,})';

  constructor(
    private spinner: NgxSpinnerService,
    private _toster: NotifectionService,
    private userService: UserServiceService,
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private location: Location
  ) {
    this.routeSub = this.route.params.subscribe(params => {
      this.ID = params['id'];
    });
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      link: ['', [Validators.required, Validators.pattern(this.urlPattern)]],
      image: ['', Validators.required],
    });

    this.form.valueChanges.subscribe(() => this.validateForm());
  }

  checkLinkField() {
    const linkControl = this.form.get('link');
    if (linkControl) {
      if (linkControl.value && !linkControl.value.startsWith('http')) {
        linkControl.setErrors({ customError: 'Link must start with http or https' });
      } else {
        if (linkControl.errors && !linkControl.errors.pattern) {
          delete linkControl.errors.customError;
          if (Object.keys(linkControl.errors).length === 0) {
            linkControl.setErrors(null);
          }
        }
      }
    }
  }

  clearValidationMessage(key: string) {
    const index = this.invalid.indexOf(key);
    if (index !== -1) {
      this.invalid.splice(index, 1);
    }
  }

  validateForm() {
    this.invalid = [];
    const controls = this.form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        this.invalid.push(name);
      }
    }
    return this.invalid.length === 0;
  }

  cancel() {
    this.location.back();
  }

  edit() {
    if (this.validateForm()) {
      const formData = new FormData();
      this.spinner.show();
      if (this.logo) {
        formData.append('image', this.logo);
      }
      formData.append('title', this.form.get('title')?.value);
      formData.append('description', this.form.get('description')?.value);
      formData.append('link', this.form.get('link')?.value);

      this.userService.addProduct(formData).subscribe(
        res => {
          this.spinner.hide();
          if (res.success == false) {
            this._toster.showError(res.message);
          }
          if (res.success == true) {
            this._toster.showSuccess(res.message);
            this.location.back();
          }
        },
        err => {
          this.spinner.hide();
          this._toster.showError(err.error.message);
        }
      );
    }
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

  onFileChange(event) {
    this.logo = event.target.files[0] && event.target.files[0];
  }
}
