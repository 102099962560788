import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private url: string;

  constructor(private authService:AuthService , private router : Router){}

private handleAuthstate() : boolean {
  if(this.isLoginOrRegister()){
    return false
  }
  return true
};


private handleNotAuthstate(): boolean {
  if ( this.isLoginOrRegister() ) {
    return true;
  }

  this.router.navigate(['/login']);
  return false;
}





private isLoginOrRegister() : boolean {
  if(this.url.includes('login') || this.url.includes('register')) {
  return true
  }
  return false
};








  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      this.url = state.url;
      // console.log(this.handleAuthstate())
      if(this.authService.isAuthenticated()){

        return this.handleAuthstate();
      }
      return this.handleNotAuthstate();
  }
  
}
