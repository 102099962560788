import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {api} from './api';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private http : HttpClient) { }

  deleteSpeciality(id) : Observable<any>
  {
    return this.http.delete(`${api.deleteSpeciality}/${id}`);
  }

  getSpecialityList(page , limit , search='') : Observable<any>
  {
    return this.http.get(`${api.getSpecialityList}?page=${page}&limit=${limit}&search=${search}`);
  }

  addSpeciality(data): Observable<any>
  {
    return this.http.post(`${api.addSpeciality}` , data);
  }

  editSpeciality(id , data): Observable<any>
  {
    return this.http.post(`${api.editSpeciality}/${id}` , data);
  }

  getSpecialityName(id) : Observable<any>
  {
    return this.http.get(`${api.getSpeciality}/${id}`);
  }
  
}