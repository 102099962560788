<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
	<p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<!-- Main Content -->
<div class="main-content">
	<section class="section">
		<div *ngIf="message" class="alert alert-success" role="alert">
			<strong>{{datas}}</strong>
		</div>
		<div *ngIf="error_message" class="alert alert-error" role="alert">
			<strong>{{datas}}</strong>
		</div>
		<!-- Tabs---Pills -->
		<div class="section-header">
		</div>
		<div class="section-body">
			<div class="row">
				<div class="col-12">
					<div class="card">
						<div class="card-header d-Fle">
							<h5>Booking Details</h5>
							<!-- <a href="" data-toggle="modal" data-target=".bd-example-modal-lg">Add Users</a> -->
						</div>
						<div class="card-body">
							<div class="adminshare">
								Total Admin Share: {{totalAdminShare}}
							</div>


							<div class="filter">

								<label style="font-size: 18px;"><b>Filter :-</b></label>

								<label style="margin-left:1% !important; font-size: 16px; ">Booking Date:</label>
								<input style="margin-left:1% !important; font-size: 16px; " type="date"
									[(ngModel)]="startDate" placeholder="Start Date" />

								<button style="margin-left:4%; color:red;" (click)="filterDataByDateRange()"><b>Apply
										Filter</b>
								</button>

								<div style="color:red">{{validation}}</div>
							</div>
							<div *ngIf="!this.allUsers?.length" class="form-group search-box pull-right "
								style="top : 50px">
								<div [formGroup]="form" class="d-flex align-items-center">
									<input type="text" style="padding-right : 45px ;width: 340px;" name="search"
										formControlName="search" class="search form-control"
										placeholder="Search by Doctor, Patient, Price, Status "
										(keyup.enter)="searchData()">
									<span class="search-icon" style="right: 29px;" (click)="clear()"><span
											class="fas fa-times-circle" style="font-size: 15px;"></span></span>
									<span class="search-icon" (click)="searchData()"><span
											class="fas fa-search"></span></span>
								</div>
							</div>
							<div class="table-responsive newPc"
								[ngStyle]="{display: this.allUsers?.length ?'inherit' : 'none'}">
								<div *ngIf="this.allUsers?.length" class="form-group search-box pull-right "
									style="top : 50px">
									<div [formGroup]="form" class="d-flex align-items-center">
										<input type="text" style="padding-right : 45px; width: 340px;" name="search"
											formControlName="search" class="search form-control"
											placeholder="Search by Doctor, Patient, Price, Status"
											(keyup.enter)="searchData()">
										<span class="search-icon" style="right: 29px;" (click)="clear()"><span
												class="fas fa-times-circle" style="font-size: 15px;"></span></span>
										<span class="search-icon" (click)="searchData()"><span
												class="fas fa-search"></span></span>
									</div>
								</div>

								<table class="table table-striped table-hover table-responsive newPc" id="tableExport" style="width:100%;">
									<thead>
										<tr>
											<th>S. NO.</th>
											<th>Doctor</th>
											<th>Patient</th>
											<th>Booking Date</th>
											<th>Booking Time</th>
											<th>Booking Status</th>
											<th>Date</th>
											<th>Transaction Id</th>
											<th>Amount</th>
											<th>Admin Share</th>
											<th>doctor Share</th>
										</tr>
									</thead>
									<tbody>
										<tr
											*ngFor="let user of allUsers | paginate: { itemsPerPage: 10, currentPage: page , totalItems: totalItem}; let i = index; let c = count">
											<td>{{i+1 + (this.page > 1 ? (this.page-1) * 10 : 0)}}</td>
											<td>{{user?.doctor_id?.username}}</td>
											<td>{{user.patient_id?.username}}</td>
											<td>{{user?.date | date: 'yyyy-MM-dd'}}</td>
											<td>{{user?.time}}</td>
											<td>{{user?.status}}</td>
											<td>{{user?.payment_id?.date | date: 'yyyy-MM-dd'}}</td>
											<td>{{user?.payment_id?.paypal_id}}</td>
											<td>{{user?.price?.price}}</td>
											<td>{{user.admin_share}}</td>
											<td>{{user.doctor_share}}</td>
										</tr>
									</tbody>
								</table>

								<pagination-controls *ngIf="this.allUsers?.length" (pageChange)="page = $event"
									(pageChange)="getPgaeObj($event)" screenReaderPaginationLabel="Pagination"
									screenReaderPageLabel="page"
									screenReaderCurrentLabel="You're on page"></pagination-controls>
								<p class="table-pagination-info">Showing {{1 + (this.page > 1 ? (this.page-1) * 10 :
									0)}} to {{(10 + (this.page > 1 ? (this.page-1) * 10 : 0) ) > totalItem ? totalItem :
									10 + (this.page > 1 ? (this.page-1) * 10 : 0) }} of {{totalItem}} entries</p>
							</div>
							<div class="d-flex justify-content-center" *ngIf="!this.allUsers?.length">
								<img src="../../../assets/images/9264885.jpg" height="500px" width="500px" alt="">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</div>