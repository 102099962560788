import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifectionService } from 'src/app/services/notifection.service';
import { UserServiceService } from 'src/app/services/user-service.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-doctor-management',
  templateUrl: './doctor-management.component.html',
  styleUrls: ['./doctor-management.component.css']
})
export class DoctorManagementComponent implements OnInit {
  allUsers:any;
  addUserForm:FormGroup
  isDataTable:boolean = true;
  updateUser:FormGroup
  userId:any;
  defaultAuthentication: string = 'Admin';
 message:boolean=false;
 alert:any;
 datas:any;
 error_message:boolean=false;
 selectedValue : any ; 
 element;
 page = 1 ;
 totalItem = 0;
 limit = 10;
 search = '';
 form;

  constructor( 
    private userService: UserServiceService ,
     private fb:FormBuilder , 
     private http: HttpClient,
     private _toster: NotifectionService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      search : ['']
    });
    this.getUsers();
  }

  loadExternalScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  searchData()
  {
    this.search = this.form.get('search').value;
    this.getUsers();
  }
  
  getUsers() {
    this.spinner.show();
    this.userService.getDoctorsData(this.page , this.limit , this.search).subscribe((res)=>{
      this.allUsers = res['data'];
      this.totalItem = res['total']
 //     console.log("All users ",this.allUsers)
      this.spinner.hide();
      // this._toster.showSuccess(res['message']);
      if (this.isDataTable) {
        this.loadExternalScript('assets/js/datatables.js');
        this.isDataTable=false;
       }
    },
    (err) => {
      this._toster.showError("No Internet Connection !");
      this.spinner.hide();
     
    }
    )
  }

  toggleUserStatus($event , user  , index)
  {
    
   // console.log($event.srcElement.checked);
    const data = { status : $event.srcElement.checked , email :  user.email};

    this.spinner.show();
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
    this.userService.setstatus(data).subscribe((res)=>{
   //   console.log(res['data']);
      // this.allUsers[index] = res['data'];
      this.getUsers();
      this.spinner.hide();
      this._toster.showSuccess(res['message']);

    },
    (err) => {
      this._toster.showError(err.error.message);
      this.spinner.hide();
      this.allUsers=[];
    }
    )
   
  }

  FadeOutSuccessMsg() {
    setTimeout( () => {
           this.message = false;
        }, 5000);
   }

   FadeOutErrorMsg() {
    setTimeout( () => {
           this.error_message = false;
        }, 5000);
   }

   DownloadFile(user){
    this.spinner.show();
    this.userService.downloadDocs(user._id).subscribe((res)=>{

      let blob:Blob = res.body as Blob;
      let a = document.createElement('a');
      a.download = `${user.username}.zip`; 
      a.href = window.URL.createObjectURL(blob);
      a.click();
      this.spinner.hide();

    },
    (err) => {
      this._toster.showError("Something went wrong !");
      this.spinner.show();
      // this.allUsers=[];
    }
    )
  }

  getPgaeObj($event)
  {
    this.page = $event;
    this.getUsers();
  }
  
  delete(id)
  {
    if (confirm("Are you sure to delete the record ? ")) {
    this.spinner.show();
    this.userService.deleteUser(id).subscribe(res =>
      {
        this.spinner.hide();
        if(res.success)
        {
          this._toster.showSuccess(res.message);
          this.getUsers();
        }
      },err =>
      {
        this._toster.showError(err.error.message);
        this.spinner.hide();
      })}
  }

  clear()
  {
    this.form.controls['search'].setValue('');
    this.search = '';
    this.getUsers();
  }
  









  downloadFile1(documents: any[] , user) {
    //console.log('user----------',user);
    for (let document of documents) {
      // Assuming each document has a URL and a filename
      const url = document.fileLink;
      const documentName = document.filename;
      const userEmail = user.email;
      const filename = `${userEmail}_${documentName}.pdf`;
      // Fetch the document content
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          // Create a blob URL for the document content
          const blobUrl = window.URL.createObjectURL(blob);
  
          // Create a temporary <a> element to trigger the download
          const link = window.document.createElement('a');
          link.href = blobUrl;
          link.download = filename;
          link.style.display = 'none';
          
          // Append the link to the body and trigger the download
          window.document.body.appendChild(link);
          link.click();
          
          // Clean up: remove the link and revoke the blob URL
          window.document.body.removeChild(link);
          window.URL.revokeObjectURL(blobUrl);
        })
        .catch(error => {
          console.error('Error downloading document:', error);
        });
    }
  }
  
  

}
