import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
// import {InfoService} from '../../services/info.service';
import {NotifectionService} from '../../services/notifection.service';
import {NgxSpinnerService} from 'ngx-spinner';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { UserServiceService } from 'src/app/services/user-service.service';
@Component({
  selector: 'app-content-management',
  templateUrl: './content-management.component.html',
  styleUrls: ['./content-management.component.css']
})

export class ContentManagementComponent implements OnInit {
  addContentForm: FormGroup
  defaultAuthentication: string = 'Admin';
  allDatas: any;
  termsCondition: any;
  privacyPolicy: any;
  language = [ {key : "en" , value : "English"},
                {key : "viet" , value : "Vietnamese"},
                {key : "ch" , value : "Chinese"},
                {key : "sp" , value : "Spanish"},
                {key : "ko" , value : "Korean"},
                {key : "hi" , value : "Hindi"},
              ]
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: ''
  };
  elementRef: any;
  invalid = [];
  constructor(public userService : UserServiceService , private fb: FormBuilder , private _toster: NotifectionService, private spinner: NgxSpinnerService) { }


  loadExternalScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }
  ngOnInit(): void {
    this.addFormData();
   
  }

  addFormData() {
    this.addContentForm = this.fb.group({
      privacy_policy:['',[ Validators.required]],
      terms_and_conditions:['',[ Validators.required]],
      lang : ['' , [Validators.required]]
      
    });
    //this.addContentForm.get('authentication').setValue(this.defaultAuthentication , { onlySelf: true } )
  };

  validate()
  {
    console.log(this.addContentForm.get('privacy_policy').value.replace('<p>','').replace('</p>','').replace(/&nbsp;/g, ' '))
    let a =this.addContentForm.get('privacy_policy').value.replace('<p>','').replace('</p>','').replace(/&nbsp;/g, ' ').trim() 
    let b  = this.addContentForm.get('terms_and_conditions').value.replace('<p>','').replace('</p>','').replace(/&nbsp;/g, ' ').trim()
   
    console.log(a , b );
    if( a && b )
    {
     // console.log(this.addContentForm.value)
      return true;
    }
    return false;
  }

  getData()
  {
    this.getContent();
  }

  onContentSubmit(){
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });

    if(this.validateForm())
    {
      this.spinner.show();
      const payload = {
        privacy_policy: this.addContentForm.value['privacy_policy'],
        terms_and_conditions: this.addContentForm.value['terms_and_conditions'],
        language : this.addContentForm.value['lang']
      }

      this.userService.addPolicy(payload).subscribe(res =>
        {
          this.spinner.hide();
          if(res.success)
          {
            this._toster.showSuccess(res.message);
          }
          else{
            this._toster.showError(res.message);
          }
        },
        err =>
        {
          this.spinner.show();
          this._toster.showError("Something went wrong !")
        })
    }
 
    
  }



  validateForm()
  {
    const controls = this.addContentForm.controls;
   // console.log("controls" , controls)
    for (const name in controls) {
        if (controls[name].invalid) {
            this.invalid.push(name);
        }
    }
  
    //console.log(this.invalid);
    return this.invalid.length ? false : true;
  }

  getContent(){
    this.spinner.show();
    const language = this.addContentForm.value['lang'];
    this.userService.getPolicy(language).subscribe(res =>
      {
        this.spinner.hide();
        if(res.success)
        {
          if(res.data.length)
          {
            this.addContentForm.patchValue({
              privacy_policy : res.data[0].privacy_policy,
              terms_and_conditions: res.data[0].terms_and_conditions
            });
          }
          else{
            this.addContentForm.patchValue({
              privacy_policy : '',
              terms_and_conditions: ''
            });
          }
          
        }
        else{
          this._toster.showError(res.message);
        }
      },
      err =>
      {
        this.spinner.hide();
        this._toster.showError("No Internet Connection !")
      })
  }

  clearValidationMessage(key)
  {
    
    if(this.invalid.includes(key))
    {
        this.invalid.splice(this.invalid.indexOf(key) , 1);
    }
  }

}
