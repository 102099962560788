
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
// import { InfoService } from '../services/info.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

    disabledSubmit = false
    forgotPasswordForm: FormGroup
    submitted = false;
    message: boolean = false;
    error_message: boolean = false;
    datas: any;
    constructor(private titleService: Title,
        private Formbuilder: FormBuilder,
        // private authservice: InfoService,
        private toast: ToastrService,
        private router: Router) {
    }

    ngOnInit(): void {
        this.forgotPasswordForm = this.Formbuilder.group({
            email: ['', [Validators.required]]
        })
    }

    get f() {
        return this.forgotPasswordForm.controls;
    }

    onSubmit() {
        this.submitted = true
        if (this.forgotPasswordForm.invalid) {
            return
        }
        this.disabledSubmit = true
        // this.authservice.forgot_password(this.forgotPasswordForm.value).subscribe(res => {
        //     this.forgotPasswordForm.reset();
        //     if(res.status=true){
        //     // this.toast.success(res.message);
        //     this.datas = res.message;
        //     console.log('=========',this.datas);
        //     this.message = true
        //     this.disabledSubmit = false
        //     this.submitted = false
        //     }else{
        //         this.datas = res.message;
        //         this.message = true
        //     }
        // }, errorResponse => {
        //     console.log(errorResponse);
        //      //  this.toast.error(errorResponse.error.message);

        //     this.datas = errorResponse.error.message;
        //     this.error_message = true
        //     this.disabledSubmit = false
        // })
    }



    FadeOutSuccessMsg() {
        setTimeout(() => {
            this.message = false;
        }, 5000);
    }


    FadeOutErrorMsg() {
        setTimeout(() => {
            this.error_message = false;
        }, 5000);
    }

}
