import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardHomeComponent } from './dashboard-home/dashboard-home.component';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { UserManagementComponent } from './dashboard/user-management/user-management.component';
import { ContentManagementComponent } from './dashboard/content-management/content-management.component';
import { AuthGuard } from './services/auth.guard';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ContactusComponent } from './dashboard/contactus/contactus.component';
import { DoctorManagementComponent } from './dashboard/doctor-management/doctor-management.component';
import { PatientManagementComponent } from './dashboard/patient-management/patient-management.component';
import { SignupManagementComponent } from './dashboard/signup-management/signup-management.component';
import { ProfileManagementComponent } from './dashboard/profile-management/profile-management.component';
import { CmsComponent } from './dashboard/cms/cms.component';
import { CmsListingComponent } from './dashboard/cms-listing/cms-listing.component';
import { ChangePasswordComponent } from './dashboard/change-password/change-password.component';
import { SpecialityComponent } from './dashboard/speciality/speciality.component';
import { SpecialityListingComponent } from './dashboard/speciality-listing/speciality-listing.component';
import { BookingDetailsComponent } from './dashboard/booking-details/booking-details.component';
import { PaymentDetailsComponent } from './dashboard/payment-details/payment-details.component';
import { AdminShareComponent } from './dashboard/admin-share/admin-share.component';
import { WithdrawalComponent } from './dashboard/withdrawal/withdrawal.component';
import { WithdrawalEditComponent } from './dashboard/withdrawal-edit/withdrawal-edit.component';
import { BankDetailsComponent } from './dashboard/bank-details/bank-details.component';
import { UploadContentComponent } from './dashboard/upload-content/upload-content.component';
import { AddImageComponent } from './dashboard/add-image/add-image.component';
import { UpdateImageComponent } from './dashboard/update-image/update-image.component';
import { BookingFilterComponent } from './dashboard/booking-filter/booking-filter.component';
import { ProductComponent } from './dashboard/product/product.component';
import { ProductAddComponent } from './dashboard/product-add/product-add.component';
import { ProductUpdateComponent } from './dashboard/product-update/product-update.component';



const routes: Routes = [
    { path: 'forgot-password', component: ForgotPasswordComponent },
    {
        path: 'login',
        canActivate: [AuthGuard],
        loadChildren: () => import('./auth/auth.module').then((res) => res.AuthModule)
    },

    {
        path: '', canActivate: [AuthGuard], component: DashboardHomeComponent, children: [
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            { path: 'dashboard', component: DashboardComponent },
            { path: 'user-management', component: UserManagementComponent },
            { path: 'doctor-management', component: DoctorManagementComponent },
            { path: 'patient-management', component: PatientManagementComponent },
            { path: 'signup-management', component: ProfileManagementComponent },
            { path: 'contact-us', component: ContactusComponent },
            { path: 'cms/add', component: CmsComponent },
            { path: 'cms-listing', component: CmsListingComponent },
            { path: 'cms/edit/:id', component: CmsComponent },
            { path: 'profile-management', component: ProfileManagementComponent },
            { path: 'privacy-policy', component: ContentManagementComponent },
            { path: 'change-password', component: ChangePasswordComponent },
            { path: 'speciality-list', component: SpecialityListingComponent },
            { path: 'speciality/add', component: SpecialityComponent },
            { path: 'speciality/edit/:id', component: SpecialityComponent },
            { path: 'booking-details', component: BookingDetailsComponent },
            { path: 'payment-details', component: PaymentDetailsComponent },
            { path: 'admin-share', component: AdminShareComponent },
            { path: 'withdrawal-req', component: WithdrawalComponent },
            { path: 'withdrawal-view/:id', component: WithdrawalEditComponent },
            { path: 'bank-details/:id', component: BankDetailsComponent },
            { path: 'upload-document', component: UploadContentComponent },
            { path: 'add-image', component: AddImageComponent },
            { path: 'update-images/:id', component: UpdateImageComponent },
            { path: 'booking-filter/:startdate', component: BookingFilterComponent },
            { path: 'product', component: ProductComponent },
            { path: 'product-add', component: ProductAddComponent },
            { path: 'product-update/:id', component: ProductUpdateComponent }
        ]
    }

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}