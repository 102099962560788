<div *ngIf="message"[ngClass] ="FadeOutSuccessMsg()" class="alert alert-success" role="alert">
    <strong>{{datas}}</strong> 
  </div>
  <div *ngIf="error_message" [ngClass] ="FadeOutErrorMsg()" class="alert alert-error" role="alert">
    <strong>{{datas}}</strong> 
  </div>



<div class="container-fluid">
    <div class="layout-specing">
        <div class="row custom-change-pwd-row mt-3">
            <div class="col-xl-6 col-lg-6 col-md-6 custom-cp-center-box">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Recover Your Password</h3>
                    </div>
                    <div class="card-body">
                        <form name="form" [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
                            <div class="form-group">
                                <input name="email" type="email" class="form-control"style="font-size: 18px;
                                padding: 30px 15px;" placeholder="Email Address" aria-label="Email Address" formControlName="email" [ngClass]="{'is-invalid':submitted && f['email'].errors|| f['email'].touched && f['email'].errors}">
                            </div>
                            <div *ngIf="submitted && f['email'].errors  || f['email'].touched && f['email'].errors"
                                 class="text-danger mb-4">
                                <div *ngIf="submitted && f['email'].errors">Email is required *</div>
                            </div>
                            <div class="form-group clearfix">
                                <button  type="submit" class="btn btn-lg btn-info btn-theme" [disabled]="disabledSubmit">
                                    <div *ngIf="disabledSubmit" class="spinner-border text-light" role="status">
                                    </div>
                                    <span class="button-style-loading">{{!disabledSubmit?"Send Me Email":""}}</span>
                                </button>
                            </div>
                        </form>
                        <p class="mb-0"> <a [routerLink]="['/login']" class=" forgot-password"style=" font-size: 19px;
                            margin-left: 29px"><span>Login here</span></a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>