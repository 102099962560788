import { Component, OnInit } from '@angular/core';
// import { InfoService } from '../../services/info.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifectionService } from '../../services/notifection.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { UserServiceService } from 'src/app/services/user-service.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  allDatas: any;
  name: any
  constructor( private userService : UserServiceService, private _router: Router, private fb: FormBuilder, private _toster: NotifectionService, private spinner: NgxSpinnerService) { }


  ngOnInit(): void {
    this.getProfile();
  }
  getProfile() {
    this.userService.getProfileData().subscribe((res) => {
      const data = res['data'] ;
      this.name = data.username
   //   console.log('------', this.name);
    },
      (err) => {
        // this.allDatas=[];
        this.name = [];
      }
    )
  }
  
  logOut() {
    if (confirm("Are you sure you want to Log Out?")) {
      localStorage.clear();
      this._router.navigate(['/login'])
    } else {
      return false; // Return false to prevent the default behavior (page refresh)
    }
  }
}
