<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<div class="main-content">
  <section class="section">
    <div class="section-header">
      <h1>Privacy Policy</h1>
    </div>
    <div class="section-body">
      <div class="row">
        <div class="col-12">
          <div class="card mt-2">
            <div class="card-body">
              <form [formGroup]="addContentForm" (ngSubmit)="onContentSubmit()" enctype="multipart/form-data">
                
                <div class="row">
                  <div class="col-lg-2 col-md-12 col-sm-12 mt-4 mb-3">
                    <div>
                      <div class="form-group mb-2">
                        <label>Choose Language</label>
                      </div>
                      <select class="form-control" formControlName="lang" placeholder="select language" (change)="clearValidationMessage('lang'); getData()">
                        <option value="" disabled selected hidden>Choose a Language</option>
                        <option *ngFor="let item of language" value="{{item.key}}">{{item.value}}</option>
                      </select>
                      <span *ngIf="this.invalid.includes('lang')" class="danger"><span class="fas fa-info-circle mr-2"></span>Language is required !</span>  
                    </div>
                    </div>
                </div>

                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <!-- <input type="hidden" formControlName="role"> -->
                    <div class="form-group">
                      <label>Terms & Condition</label>
<!--                      <div class="NgxEditor__Wrapper">-->
<!--                        <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>-->
<!--                        <ngx-editor-->
<!--                          [editor]="editor"-->
<!--                          formControlName="terms_and_conditions"-->
<!--                          [placeholder]="'Type here...'"-->
<!--                        ></ngx-editor>-->
<!--                      </div>-->
                      <angular-editor formControlName="terms_and_conditions" [config]="editorConfig"></angular-editor>
<!--                      <textarea class="form-control" formControlName="" id="editor"></textarea>-->
                      <!-- <app-input-error [control]="addContentForm.get('terms_and_conditions')" [MsgKey]="'Terms & Condition'"></app-input-error> -->
                      <span *ngIf="this.invalid.includes('terms_and_conditions')" class="danger"><span class="fas fa-info-circle mr-2"></span>Terms & Condition is required !</span>  
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group">
                      <label>Privacy Policy</label>
<!--                      <div class="NgxEditor__Wrapper">-->
<!--                        <ngx-editor-menu [editor]="editor1"> </ngx-editor-menu>-->
<!--                        <ngx-editor-->
<!--                          [editor]="editor1"-->
<!--                          formControlName="privacy_policy"-->
<!--                          [placeholder]="'Type here...'"-->
<!--                        ></ngx-editor>-->
<!--                      </div>-->
                      <angular-editor formControlName="privacy_policy" [config]="editorConfig"></angular-editor>
<!--                      <textarea class="form-control" formControlName="privacy_policy"></textarea>-->
                      <!-- <app-input-error [control]="addContentForm.get('privacy_policy')" [MsgKey]="'Privacy Policy'"></app-input-error> -->
                      <span *ngIf="this.invalid.includes('privacy_policy')" class="danger"><span class="fas fa-info-circle mr-2"></span>Privacy Policy is required !</span>  
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-2 col-md-12 col-sm-12 mt-4">

                    <input type="submit" value="Add"  class="modal-btn">
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
