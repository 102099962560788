import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CmsService } from 'src/app/services/cms.service';
import { NotifectionService } from 'src/app/services/notifection.service';
import { UserServiceService } from 'src/app/services/user-service.service';

@Component({
  selector: 'app-cms-listing',
  templateUrl: './cms-listing.component.html',
  styleUrls: ['./cms-listing.component.css']
})
export class CmsListingComponent implements OnInit {
  cmsList:any;
  toppingList = [
     "abc"
  ]

  lang  = {
    'ko': "korean",
    'sp':"Spanish",
    'hi':"Hindi",
    'viet' : "Vietnamese",
    'en' : "English",
    'ch' : "Chinese"
  }
  isDataTable:boolean = true;
  
  userId:any;
  defaultAuthentication: string = 'Admin';
  message:boolean=false;
  alert:any;
  datas:any;
  error_message:boolean=false;
 page = 1;
 limit = 10;
 totalItem = 0;
 search = '';
 form;
  constructor(public router : Router ,private userService: UserServiceService  , public cmsService : CmsService , private fb:FormBuilder , private _toster: NotifectionService, private spinner: NgxSpinnerService) { }

  loadExternalScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      search : ['']
    });
    this.getlist();
  }

  getlist() {
    this.spinner.show();
    this.cmsService.getListing( this.page , this.limit , this.search ).subscribe((res)=>{
      this.cmsList = res['data'];
      this.totalItem = res.total;
      console.log("List ",this.cmsList);
      this.spinner.hide();
      // this._toster.showSuccess(res['message']);
      if (this.isDataTable) {
        this.loadExternalScript('assets/js/datatables.js');
        this.isDataTable=false;
       }
    },
    (err) => {
      this._toster.showError("No Internet Connection !");
      this.spinner.show();
      this.cmsList=[];
    }
    )
  }

  edit(id , user)
  {
    this.router.navigate([`/cms/edit/${user._id}`] , { state: { id: user._id } });
  }

  getPgaeObj($event)
  {
    this.page = $event;
    this.getlist();
  }

  deletePost(id)
  {
    if (confirm("Are you sure to delete the record ? ")) {
    this.spinner.show();
    this.cmsService.deletePost(id).subscribe(res =>
    {
      if(res.success)
      {
        this._toster.showSuccess(res.message);
        this.getlist();
        this.spinner.hide();
      }
      else
      {
        this.spinner.hide();
        this._toster.showError(res.message);
      }
      
    });}
  }

  searchData()
  {
    this.search = this.form.get('search').value;
    this.getlist();
  }

  clear()
  {
    this.form.controls['search'].setValue('');
    this.search = '';
    this.getlist();
  }

  FadeOutSuccessMsg() {
    setTimeout( () => {
           this.message = false;
        }, 5000);
   }

   FadeOutErrorMsg() {
    setTimeout( () => {
           this.error_message = false;
        }, 5000);
   }
}
