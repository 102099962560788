import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifectionService } from 'src/app/services/notifection.service';
import { UserServiceService } from 'src/app/services/user-service.service';

@Component({
  selector: 'app-signup-management',
  templateUrl: './signup-management.component.html',
  styleUrls: ['./signup-management.component.css']
})
export class SignupManagementComponent implements OnInit {

  allUsers:any;
  addUserForm:FormGroup
  isDataTable:boolean = true;
  updateUser:FormGroup
  userId:any;
  defaultAuthentication: string = 'Admin';
 message:boolean=false;
 alert:any;
 datas:any;
 error_message:boolean=false;
 selectedValue : any ; 
 element;
  constructor( private userService: UserServiceService , private fb:FormBuilder , private _toster: NotifectionService, private spinner: NgxSpinnerService) { }

  loadExternalScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  ngOnInit(): void {
    this.getUsers();

  }



  getUsers() {
    this.spinner.show();
    this.userService.getEnrolledDoctors().subscribe((res)=>{

      this.allUsers = res['data'];

      //console.log("All users ",this.allUsers);
      this.spinner.hide();
      this._toster.showSuccess(res['message']);

      if (this.isDataTable) {
        this.loadExternalScript('assets/js/datatables.js');
        this.isDataTable=false;
       }
    },
    (err) => {
      this._toster.showError(err.error.message);
      this.spinner.hide();
      this.allUsers=[];
    }
    )
  }

  toggleUserStatus($event , user  , index)
  {
 //   console.log($event.srcElement.checked);
    const data = { status : $event.srcElement.checked , email :  user.email};

    this.spinner.show();
    this.userService.setstatus(data).subscribe((res)=>{
  //    console.log(res['data']);
      this.allUsers[index] = res['data'];
      this.spinner.hide();

    },
    (err) => {
      this._toster.showError(err.error.message);
      this.spinner.hide();
      this.allUsers=[];
    }
    )
  }

 
  onDelete(id : any) {
  //   if(confirm("Are you sure to delete the record ? ")) {
  //   this.info.deleteUserData(id).subscribe((res)=>{
  //     // console.log(res)
  //     this.getUsers();
    
  //     this.message=true;
  //     this.datas=res.message;
  //   },
  //   (err) => {
  
  //   this.error_message=true;
  //   this.datas=err.message
  //   }
  //   )
  // }
  }

  DownloadFile(user){
    this.spinner.show();
    this.userService.downloadDocs(user._id).subscribe((res)=>{

      let blob:Blob = res.body as Blob;
      let a = document.createElement('a');
      a.download = `${user.username}.zip`; 
      a.href = window.URL.createObjectURL(blob);
      a.click();
      this.spinner.hide();

    },
    (err) => {
      this._toster.showError(err.error.message);
      this.spinner.hide();
      this.allUsers=[];
    }
    )
  }

 
  FadeOutSuccessMsg() {
    setTimeout( () => {
           this.message = false;
        }, 5000);
   }

   FadeOutErrorMsg() {
    setTimeout( () => {
           this.error_message = false;
        }, 5000);
   }
}
