import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifectionService } from 'src/app/services/notifection.service';
import { Location } from '@angular/common';
import { UserServiceService } from "src/app/services/user-service.service";

@Component({
  selector: 'app-add-image',
  templateUrl: './add-image.component.html',
  styleUrls: ['./add-image.component.css']
})
export class AddImageComponent implements OnInit {
  logo: any;
  form: FormGroup;
  invalid: string[] = [];
  ID: string;
  routeSub: any;

  constructor(
    private spinner: NgxSpinnerService,
    private _toster: NotifectionService,
    private userService: UserServiceService,
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private location: Location
  ) {
    this.routeSub = this.route.params.subscribe(params => {
      this.ID = params['id'];
    });
  }

  ngOnInit(): void {
    this.createForm();
    //   this.getWithdrawalDetails()
  }

  createForm() {
    this.form = this.fb.group({
      content: [''],
      images: ['',Validators.required],

    });
  }

  clearValidationMessage(key: string) {
    const index = this.invalid.indexOf(key);
    if (index !== -1) {
      this.invalid.splice(index, 1);
    }
  }

  validateForm() {
    this.invalid = [];
    const controls = this.form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        this.invalid.push(name);
      }
    }
    return this.invalid.length === 0;
  }

  cancel() {
    this.location.back();
  }

  edit() {
    if (this.validateForm()) {
      const formData = new FormData();
      this.spinner.show();
      if (this.logo) {

        for (let i = 0; i < this.logo.length; i++) {
          // console.log("==<<>.--", this.audio_files[i]);
         
          formData.append('images', this.logo[i]);
        }
      }
      formData.append('content', this.form.get('content')?.value);
    
      this.userService.addImage(formData).subscribe(
        res => {
          this.spinner.hide();
          this._toster.showSuccess(res.message);
          this.location.back();
        },
        err => {
          this.spinner.hide();
          this._toster.showError(err.error.message);
        }
      );
    }
  }


  getWithdrawalDetails() {
    //   console.log('this.ID----------------',this.ID)
    this.userService.getWithdrawalReqById(this.ID).subscribe((res) => {
      this.spinner.hide();
      //  console.log('===============ryeww',res.data.doctor_id?.username)
      this.form.patchValue({
        name: res.data?.doctor_id?.username,
        requestAmount: res.data?.amount,
        status: res.data?.status,
        amount: res.data.doctor_id?.totalIncome
      });

      //  this._toster.showSuccess(res.message);
    },
      (err) => {
        this.spinner.hide();
        this._toster.showError(err.error.message);
      })
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }
  onFileChange(event) {
    this.logo = event.target.files && event.target.files;
 //   console.log('=====================this.logo', this.logo);

  };

}
