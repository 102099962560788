import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-input-error',
  templateUrl: './input-error.component.html',
  styleUrls: ['./input-error.component.css']
})
export class InputErrorComponent implements OnInit {

  @Input() control: AbstractControl;
  @Input() MsgKey: string;
  @Input() min: string;
  @Input() max: string;
  @Input() minLength: string;
  @Input() maxLength: string;
  @Input() equal: string;
  
  constructor() { }

  ngOnInit(): void {
  }

  getMessage(field: any): string {
    if (this.control.hasError('error')) {
      // @ts-ignore
      return this.control.errors.error;
    }
    if (this.control.hasError('email')) {
      return `${field} Is Invalid`;
    }
    if (this.control.hasError('required')) {
      return `${field} Is Required`;
    }
    if (this.control.hasError('minlength')) {
      return `${field} Length Must Be Grater than ${this.minLength} digit`;
    }
    if (this.control.hasError('maxlength')) {
      return `${field} Length Must Be Less than ${this.maxLength} digit`;
    }
    if (this.control.hasError('pattern')) {
      return `${field} Is Invalid`;
    }
    if (this.control.hasError('max')) {
      return `${field}  Must Be Less than ${this.max}`;
    }
    if (this.control.hasError('min')) {
      return `${field}  Must Be Grater than ${this.min}`;
    }
    if (this.control.hasError('equal')) {
      return `${field}  Must Be equal to ${this.equal}`;
    }
    return null;
  };


}
