import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
// import { InfoService } from 'src/app/services/info.service';
import { NotifectionService } from 'src/app/services/notifection.service';
import { UserServiceService } from 'src/app/services/user-service.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  practitionerCount:any;
  userCount:any;
  categoryCount:any;
  data: any;
  // currentAndNextDays:any;

  // abc;
  // latest_dates

  constructor( public userService : UserServiceService ,  private _toster: NotifectionService, private spinner: NgxSpinnerService , public datepipe: DatePipe) { }




  public loadExternalScript(url: string) {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  ngOnInit(): void {
    this.loadExternalScript('assets/js/calendar.js');
    this.getUsers();
  }



  getUsers() {
    this.spinner.show();
    this.userService.getCount().subscribe((res)=>{
      this.data = res['data'] ;
   //   console.log(res)
      this.spinner.hide();
      
    },
    (err) => {
      this._toster.showError('No Internet Connection !');
      this.spinner.show();
      
    }
    )
  }

  




}
