import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {api} from './api';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CmsService {

  constructor(private http : HttpClient) { }
  

  addPost(payloadObject): Observable<any> {
   
    if(payloadObject.embed)
    {
      var formData: any = new FormData();

      formData.append("title" , payloadObject['title']);
      formData.append("description" , payloadObject['description']);
      formData.append("precautions" , JSON.stringify(payloadObject['precautions']));
      formData.append("symptoms" , JSON.stringify(payloadObject['symptoms']));
      formData.append("lang" , payloadObject['lang']);
      formData.append("video" , payloadObject["video"] );
      formData.append('embed' , payloadObject['embed']);
      formData.append('embedLink' , payloadObject['embedLink']);

      
      return this.http.post(`${api.addPost}`, formData);
    }
    else{
    var formData: any = new FormData();

    formData.append("title" , payloadObject['title']);
    formData.append("description" , payloadObject['description']);
    formData.append("precautions" , JSON.stringify(payloadObject['precautions']));
    formData.append("symptoms" , JSON.stringify(payloadObject['symptoms']));
    formData.append("lang" , payloadObject['lang']);
    formData.append("video" , payloadObject["video"] );
    formData.append("thumbnail" , payloadObject["thumbnail"]);
    formData.append('embed' , payloadObject['embed']);
    
    return this.http.post(`${api.addPost}`, formData);
    }
  }

  editPost(payloadObject , id) :Observable<any>
  {
   
    if(!payloadObject.embed)
    { 
      var formData: any = new FormData();

      formData.append("title" , payloadObject['title']);
      formData.append("description" , payloadObject['description']);
      formData.append("precautions" , JSON.stringify(payloadObject['precautions']));
      formData.append("symptoms" , JSON.stringify(payloadObject['symptoms']));
      formData.append("lang" , payloadObject['lang']);
      formData.append("video" , payloadObject["video"] );
      formData.append("thumbnail" , payloadObject["thumbnail"]);
      formData.append("thumbnailFileName" , payloadObject["thumbnailFileName"]);
      formData.append("videoFileName" , payloadObject["videoFileName"]);
      formData.append('embed' , payloadObject['embed']);
    
      return this.http.post(`${api.editPost}/${id}`, formData);
    }
    else{
      var formData: any = new FormData();

      formData.append("title" , payloadObject['title']);
      formData.append("description" , payloadObject['description']);
      formData.append("precautions" , JSON.stringify(payloadObject['precautions']));
      formData.append("symptoms" , JSON.stringify(payloadObject['symptoms']));
      formData.append("lang" , payloadObject['lang']);
      formData.append("video" , payloadObject["video"] );
      formData.append("thumbnail" , payloadObject["thumbnail"]);
      formData.append("thumbnailFileName" , payloadObject["thumbnailFileName"]);
      formData.append("videoFileName" , payloadObject["videoFileName"]);
      formData.append('embed' , payloadObject['embed']);
      formData.append('embedLink' , payloadObject['embedLink']);
      
      return this.http.post(`${api.editPost}/${id}`, formData);
    }
  }

  getListing(page  , limit  , search = '') : Observable<any>
  {
    return this.http.get(`${api.getAllPost}?page=${page}&limit=${limit}&search=${search}`);
  }

  getPost(id): Observable<any>
  {
   
    return this.http.get(`${api.getPost}/${id}`);
  }

  deletePost(id) : Observable<any>
  {
    
    return this.http.delete(`${api.deletePost}/${id}`);
  }

}
