import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifectionService } from 'src/app/services/notifection.service';
import { Location } from '@angular/common';
import { UserServiceService } from "src/app/services/user-service.service";

@Component({
  selector: 'app-update-image',
  templateUrl: './update-image.component.html',
  styleUrls: ['./update-image.component.css']
})
export class UpdateImageComponent implements OnInit, OnDestroy {

  logo: any;
  imageValues: any = [];
  form: FormGroup;
  invalid: string[] = [];
  ID: string;
  routeSub: any;

  constructor(
    private spinner: NgxSpinnerService,
    private _toster: NotifectionService,
    private userService: UserServiceService,
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private location: Location
  ) {
    this.routeSub = this.route.params.subscribe(params => {
      this.ID = params['id'];
    });
  }

  ngOnInit(): void {
    this.createForm();
    this.getDetails();
  }

  createForm() {
    this.form = this.fb.group({
      content: [''],
      images: ['']
    });
  }

  clearValidationMessage(key: string) {
    const index = this.invalid.indexOf(key);
    if (index !== -1) {
      this.invalid.splice(index, 1);
    }
  }

  validateForm() {
    this.invalid = [];
    const controls = this.form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        this.invalid.push(name);
      }
    }
    // Custom validation check for images
    if (!this.logo && this.imageValues.length === 0) {
      this.invalid.push('images');
    }
    return this.invalid.length === 0;
  }

  cancel() {
    this.location.back();
  }

  edit() {
    if (this.validateForm()) {
      const formData = new FormData();
      this.spinner.show();
      if (this.logo) {
        for (let i = 0; i < this.logo.length; i++) {
          formData.append('images', this.logo[i]);
        }
      }
      formData.append('content', this.form.get('content')?.value);
      formData.append('id', this.ID);

      this.userService.updateImage(formData).subscribe(
        res => {
          this.spinner.hide();
          this._toster.showSuccess(res.message);
          this.location.back();
        },
        err => {
          this.spinner.hide();
          this._toster.showError(err.error.message);
        }
      );
    }
  }

  getDetails() {
    this.userService.getImageById(this.ID).subscribe((res) => {
      this.spinner.hide();
      let blogImage = res.data?.images;
      if (blogImage.length) {
        for (let i = 0; i < blogImage.length; i++) {
          let value = blogImage[i];
          this.imageValues.push(value);
        }
      }
      this.form.patchValue({
        content: res.data?.content,
      });
    },
    (err) => {
      this.spinner.show();
      this._toster.showError(err.error.message);
    });
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

  onFileChange(event) {
    this.logo = event.target.files && event.target.files;
  }

  onDeletePreviousImage(index: number) {
    let value = this.imageValues.splice(index, 1);
    let id = value[0]._id;
    let obj = {
      documentId: this.ID,
      id: id
    };
    this.userService.deleteArrayImage(obj).subscribe(res => {
      this.spinner.hide();
    }, err => {
      this._toster.showError(err.error.message);
      this.spinner.hide();
    });
  }
}
