import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
// import { InfoService } from '../../services/info.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifectionService } from '../../services/notifection.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserServiceService } from 'src/app/services/user-service.service';
@Component({
  selector: 'app-profile-management',
  templateUrl: './profile-management.component.html',
  styleUrls: ['./profile-management.component.css']
})
export class ProfileManagementComponent implements OnInit {
  addProfileForm: FormGroup;
  profileImage: any;
  logo: any;
  allDatas: any;
  showSuccess: any;
  name;
  email;
  editflag ;
  form ;
  invalid = [];
  url;
  baseImageUrl = 'https://docnowllc.com/images/'
  @ViewChild('profileImage') thumbnailInput: ElementRef;
  emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
  
  constructor( private userService : UserServiceService, private fb: FormBuilder, private toster: NotifectionService, private spinner: NgxSpinnerService)
   { 
    this.form = this.fb.group({
      username : ['' , [Validators.required]],
      email : ['' , [ Validators.required]]
    });
   }

  ngOnInit(): void {
    this.editflag = false;
    this.getProfile();
  }

  getProfile() {
    this.spinner.show();
    this.userService.getProfileData().subscribe((res) => {
      this.spinner.hide();
      if(res.success)
      {
        const data = res['data'] ;
        this.name = data.username;
        this.email = data.email;
        console.log(data.profileUrl);
        this.url = data.profileUrl;
        this.form.patchValue({
          username : data.username,
          email : data.email
        });
      }
      else{
        this.toster.showError(res.message);
        this.name = '!Error';
        this.email = '!Error';
      }
      
    },
      (err) => {
        
        this.name = '';
        this.email = '';
      }
    )
  }

  edit()
  {
    this.editflag = true;
  }

  validateForm()
  {
      const controls = this.form.controls;
      for (const name in controls) {
          if (controls[name].invalid) {
              this.invalid.push(name);
          }
      }
      return this.invalid.length ? false : true;
  }

  submit()
  {
    
    this.form.patchValue({
      username : this.form.get('username').value.trim(),
      email : this.form.get('email').value.trim()
    });


    if(this.validateForm()){
    this.spinner.show();
    const payloadObj = this.form.value ;
    this.userService.editProfile(payloadObj).subscribe(res =>
      {
        this.spinner.hide();
        if(res.success)
        {
          this.toster.showSuccess(res.message);
          this.name = res.data.username;
          this.email = res.data.email;
          this.editflag =false;
          this.form.patchValue({
            username : this.name,
            email : this.email
          });
        }
        else
        {
          this.toster.showError(res.message);
          this.editflag=false;
        }
      },
      err =>
      {
        this.spinner.show();
        this.toster.showError(err.error.message)
      })
    }
  }

  reset()
  {
    this.editflag = false;
    this.form.patchValue({
      username : this.name,
      email : this.email
    });
  }

  onChange(key)
  {
      if(this.invalid.includes(key))
      {
          this.invalid.splice(this.invalid.indexOf(key) , 1);
      }
  }

  upload(event)
  {
    const file = event.target.files && event.target.files[0];
    
    this.spinner.show(); 
    this.userService.uploadProfileImage(file).subscribe(res =>
      {
        this.spinner.hide();
        if(res.success)
        {
          this.toster.showSuccess(res.message);
          this.url = res.data;
          this.userService.sendProfileLink(this.url);
        }
      },
      err =>
      {
        this.spinner.hide();
        this.toster.showError(err.error.message);
      }
      )
  }

  getImageUrl(url: string): string {
    if (!url.includes('https')) {
      return this.baseImageUrl + url;
    }
    return url; // If the URL already contains 'https', return it as is
  }

  getValidation()
  {
    if(this.emailRegex.test(this.form.get('email').value))
    {
      this.invalid.includes('email')  ? this.invalid.splice(this.invalid.indexOf('email') , 1) : "";
      return false
    }
    this.invalid.includes('email') ? '' : this.invalid.push('email');
    return true;
  }
  
}
