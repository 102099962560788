<div class="navbar-bg"></div>
<nav class="navbar navbar-expand-lg main-navbar">
  <div class="form-inline mr-auto">
    <ul class="navbar-nav mr-3">
      <li><a href="#" data-toggle="sidebar" class="nav-link nav-link-lg collapse-btn"><i class="fas fa-bars"></i></a>
      </li>
      <li>
      </li>
    </ul>
  </div>
  <ul class="navbar-nav navbar-right">
    <li class="dropdown"><a href="#" data-toggle="dropdown" class="nav-link dropdown-toggle nav-link-lg nav-link-user">
        <img alt="image" *ngIf="url?.includes('https')" src="https://api.dicebear.com/5.x/initials/svg?seed=Admin" width="90" height="60" class="user-img-radious-style">
        <img alt="image" *ngIf="!url?.includes('https')" [src]="getImageUrl(url)" width="90" height="60" class="user-img-radious-style">
        <span class="d-sm-none d-lg-inline-block"></span></a>
      <div class="dropdown-menu dropdown-menu-right">

        <a [routerLink]="['/profile-management']" class="dropdown-item has-icon "><i
            class="fas fa-user"></i><span>Profile
          </span></a>
        <a [routerLink]="['/change-password']" class="dropdown-item has-icon"><i class="fas fa-key"></i><span>Change
            Password</span></a>

        <a href="" (click)="logOut()" class="dropdown-item has-icon text-danger">
          <i style="margin-top :3px !important;" class="fas fa-sign-out-alt"></i> Logout
        </a>
      </div>
    </li>
  </ul>
</nav>