<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<div class="main-content">
    <div class="title m-auto d-flex justify-content flex-column col-md-8 mb-3">
        <h3>Withdrawal Details</h3>
        <button class="form-control btn btn-primary" (click)="cancel()" style="margin-left: 83%; width: 14%;"
            type="button">
            Go Back
        </button>
    </div>

    <div class="mb-5 mt-5">
        <div [formGroup]="form" class="d-flex justify-content flex-column form-group col-md-8 m-auto">
            <div class="mb-3 d-flex flex-column align-items-start">
                <label for="name">Doctor name</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control" formControlName="name" readonly>
                </div>
            </div>
            <!-- <div class="mb-3 d-flex flex-column align-items-start">
                <label for="amount">Current Amount</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control" formControlName="amount" readonly>
                </div>
            </div> -->
            <div class="mb-3 d-flex flex-column align-items-start">
                <label for="requestAmount">Request Amount</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control"   formControlName="requestAmount" readonly>
                </div>
            </div>
            <div class="mb-3 d-flex flex-column align-items-start">
                <label for="status">Payment Status</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control" formControlName="status" readonly>
                </div>
            </div>
            <!-- <div class="mb-3 d-flex flex-column align-items-start">
                <label for="status">Date</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control" formControlName="date" readonly>
                </div>
            </div> -->
            <!-- <div class="col-md-6">
                <label class="form-label">Payment Status<span style="color: red;">*</span></label>
                <div class="custom-select-wrap">
                    <select class="form-control select2input" formControlName="status">
                        <option value="">Select</option>
                        <option value="Accept">Accept</option>
                    </select>
                    <span class="selectOptArw"><i class="far fa-angle-down"></i></span>
                    <app-input-error [control]="form.get('status')" [MsgKey]="'Payment Status'"></app-input-error>
                </div>
            </div> -->
        </div>

    </div>
</div>