<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<div class="main-content">
    <div class="title m-auto d-flex justify-content flex-column col-md-8 mb-3">
        <h3>Bank Details</h3>
        <button class="form-control btn btn-primary" (click)="cancel()" style="margin-left: 83%; width: 14%;" type="button">
            Go Back
        </button>
    </div>

    <div class="mb-5 mt-5" *ngFor="let bank of this.bankDetails">
        <div [formGroup]="form" class="d-flex justify-content flex-column form-group col-md-8 m-auto">
            <div class="mb-3 d-flex flex-column align-items-start">
                <label for="name">Doctor name</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control" [value]="bank?.doctor_id?.username" readonly>
                </div>
            </div>
            <div class="mb-3 d-flex flex-column align-items-start">
                <label for="email">Doctor Email</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control"[value]="bank.doctor_id?.email" readonly>
                </div>
            </div>
            <div class="mb-3 d-flex flex-column align-items-start">
                <label for="card_number">Card Number</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control" [value]="bank.card_number" readonly>
                </div>
            </div>
            <div class="mb-3 d-flex flex-column align-items-start">
                <label for="holder_name">Card Holder's Name</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control" [value]="bank.holder_name" readonly>
                </div>
            </div>    <div class="mb-3 d-flex flex-column align-items-start">
                <label for="expiration_date">Expiration Date</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control" [value]="bank.expiration_date" readonly>
                </div>
            </div>  
              <div class="mb-3 d-flex flex-column align-items-start">
                <label for="security_number">Security Number</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control" [value]="bank.security_number" readonly>
                </div>
            </div>
            <div>
                <hr>
            </div>
        </div>
        
        
    </div>
</div>
