import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifectionService } from 'src/app/services/notifection.service';
import { UserServiceService } from 'src/app/services/user-service.service';
@Component({
  selector: 'app-booking-details',
  templateUrl: './booking-details.component.html',
  styleUrls: ['./booking-details.component.css']
})
export class BookingDetailsComponent implements OnInit {
  endDate: string; // Declare the endDate property
  filteredData: String; // Initialize filteredData with all data
  startDate: string; // Declare the startDate property
  dateFilter: String;
  element: any[];
  adminTotal: number;
  allUsers: any;
  addUserForm: FormGroup
  isDataTable: boolean = true;
  updateUser: FormGroup
  userId: any;
  defaultAuthentication: string = 'Admin';
  message: boolean = false;
  alert: any;
  datas: any;
  error_message: boolean = false;
  selectedValue: any;
  totalValue;
  page = 1;
  totalItem = 0;
  totalAdminShare = 0;
  limit = 10;
  search = '';
  form;
  validation:any;
  constructor(
    private userService: UserServiceService,
    private fb: FormBuilder,
    private _toster: NotifectionService, private router: Router,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      search: ['']
    });
    this.getUsers();
    this.getProfile() ;
    this.adminProfile()
  }

  loadExternalScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  searchData() {
    this.search = this.form.get('search').value;
    this.getUsers();
  }

  getUsers() {
    this.spinner.show();
    this.userService.getBookingData(this.page, this.limit, this.search).subscribe((res) => {
      this.allUsers = res['data'];
      this.totalItem = res['total'];
   //   this.totalAdminShare = 0;
      // this.allUsers.forEach(user => {
      //   if (user.admin_share !== undefined && user.admin_share !== null) {
      //     const adminShare = parseFloat(user.admin_share);
      //     if (!isNaN(adminShare)) {
      //       this.totalAdminShare += adminShare;
      //     } else {
      // //      console.warn(`--userAmount-- ${user.admin_share} is not a valid number`);
      //     }
      //   } else {
      //  //   console.warn(`--userAmount-- undefined for user ${user._id}`);
      //   }
      // });

      // Format the totalAdminShare to a fixed number of decimal places
    //  this.totalAdminShare = parseFloat(this.totalAdminShare.toFixed(2));
   //   console.log('------this.totalAdminShare-----', this.totalAdminShare)
      this.spinner.hide();
      // this._toster.showSuccess(res['message']);
      if (this.isDataTable) {
        this.loadExternalScript('assets/js/datatables.js');
        this.isDataTable = false;
      }
    },
      (err) => {
        this._toster.showError("No Internet connection !");
        this.spinner.hide();

      }
    )
  }

  toggleUserStatus($event, user, index) {

    console.log($event.srcElement.checked);
    const data = { status: $event.srcElement.checked, email: user.email };

    this.spinner.show();
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.userService.setstatus(data).subscribe((res) => {
      console.log(res['data']);
      // this.allUsers[index] = res['data'];
      this.getUsers();
      this.spinner.hide();
      this._toster.showSuccess(res['message']);

    },
      (err) => {
        this._toster.showError(err.error.message);
        this.spinner.hide();
        this.allUsers = [];
      }
    )

  }

  FadeOutSuccessMsg() {
    setTimeout(() => {
      this.message = false;
    }, 5000);
  }

  FadeOutErrorMsg() {
    setTimeout(() => {
      this.error_message = false;
    }, 5000);
  }

  DownloadFile(user) {
    this.spinner.show();
    this.userService.downloadDocs(user._id).subscribe((res) => {

      let blob: Blob = res.body as Blob;
      let a = document.createElement('a');
      a.download = `${user.username}.zip`;
      a.href = window.URL.createObjectURL(blob);
      a.click();
      this.spinner.hide();

    },
      (err) => {
        this._toster.showError("Something went wrong !");
        this.spinner.hide();
        // this.allUsers=[];
      }
    )
  }

  getPgaeObj($event) {
    this.page = $event;
    this.getUsers();
  }

  delete(id) {
    if (confirm("Are you sure to delete the record ? ")) {
      this.spinner.show();
      this.userService.deleteUser(id).subscribe(res => {
        this.spinner.hide();
        if (res.success) {
          this._toster.showSuccess(res.message);
          this.getUsers();
        }
      }, err => {
        this._toster.showError(err.error.message);
        this.spinner.hide();
      })
    }
  }

  clear() {
    this.form.controls['search'].setValue('');
    this.search = '';
    this.getUsers();
  }


  getProfile() {
    this.spinner.show();
    this.userService.getProfileData().subscribe((res) => {
      this.spinner.hide();
      if(res.success)
      {
        const data = res['data'] ;
    //    console.log('--------<<data>>----------------',data.totalIncome);
      
      }
      
      
    },
      (err) => {
        
   //     console.log('=====data--err',err)
      }
    )
  }

adminProfile(){
  this.totalAdminShare = 0;
  this.userService.getProfileData().subscribe((res) => {
//console.log('============res===',res.data);

   let value= res.data
   
   this.totalAdminShare = Math.round(value.totalIncome)
   //console.log('=============<<totalAdminShare>>===',this.totalAdminShare)
    
  },
    (err) => {
      
 
    }
  )
}

filterDataByDateRange() {
  if(!this.startDate){
    this.validation = 'Please Select Booking Date'
  }
  this.router.navigate(['/booking-filter',this.startDate])
// console.log('====<<this.startDate>>====',this.startDate, this.endDate)
  if (!this.startDate && !this.endDate) {
  //  console.log('No date filters applied. Returning all data.');
    this.allUsers = this.allUsers;
    return;
  }
  const filteredUsers = this.allUsers.filter(item => {

    const itemStartDate = new Date(item.start_date);
    const itemEndDate = new Date(item.expiration_date);
    const filterStartDate = new Date(this.startDate);
    const filterEndDate = new Date(this.endDate);
    const isStartDateInRange = itemStartDate >= filterStartDate;
    const isEndDateInRange = itemEndDate < filterEndDate;
    return isStartDateInRange && isEndDateInRange;
  });


  //console.log('===<<filteredUsers>.==', filteredUsers);
  let value = 0;
  filteredUsers.forEach(user => {

    const amountString = user.amount; // For example, "$200"
    //   console.log('========<<user.amount;>==',user.amount);
    const amountNumeric = parseFloat(amountString.replace('$', '')); // 
    value += amountNumeric;

  });
  this.totalValue = value;
//  console.log('=====<<value>==', this.totalValue)
//  this.displayedUsers = filteredUsers;
 // console.log('===<<displayedUsers>>===', this.displayedUsers);
}
}
