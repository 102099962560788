import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CmsService } from 'src/app/services/cms.service';
import { NotifectionService } from 'src/app/services/notifection.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-cms',
  templateUrl: './cms.component.html',
  styleUrls: ['./cms.component.css']
})
export class CmsComponent implements OnInit {

  language = [
    { key :"en" , value : "English" },
    { key :"sp" , value : "Spanish" },
    { key :"viet" , value : "Vietnamese" },
    { key :"ch" , value : "Chinese" },
    { key :"hi" , value : "Hindi" },
    { key :"ko" , value : "Korean" }
  ];
  url;
  format;
  thumbnailurl;
  form : FormGroup ;
  @ViewChild('videoInput') videoInput: ElementRef;
  @ViewChild('thumbnailInput') thumbnailInput: ElementRef;
  userId: any;
  localUrl = `http://localhost:3000/images/`
  devUrl = `https://docnowllc.com/api/images/`
  routeSub: any;
  edit: boolean = false;
   vidTyp = [
    {key : "Original" , value : false},
    {key : "Embed Youtube Link" , value : true}
  ];
  invalid = [];

  constructor(
    public router : Router,
    public fb : FormBuilder,
     private _toster: NotifectionService , 
     public cmsService : CmsService , 
     public location: Location,
     private spinner: NgxSpinnerService,
     private route: ActivatedRoute) { 
      this.routeSub =this.route.params.subscribe(params => {
        this.userId = params['id'];
      });
  }

  ngOnInit(): void {
     
    this.createForm();
    if(this.router.url.includes('edit'))
    {
     
      this.edit = true ;
      this.setFormValues();
    }
    else{
      this.addPrecaution();
      this.addSymptoms();
    }
  }

  onChangeVideoType()
  {
   
    this.form.get('embed').value == 'true' ?  this.setVideoType(true) : this.setVideoType(false);
  }

  createForm()
  {
    this.form = this.fb.group({
      title : ["" , [Validators.required]],
      description : ["" , [Validators.required]],
      videoFileName : [""],
      thumbnailFileName : [""],
      video : [null  , [Validators.required]],
      thumbnail : [null , [Validators.required]], 
      precautions : this.fb.array([]),
      symptoms : this.fb.array([]),
      lang : [ "" , [Validators.required]],
      embed : [false  , [Validators.required]],
      embedLink : [''  , [Validators.required]]
    });
    
   
  }

  setFormValues()
  {
    this.spinner.show();
    this.cmsService.getPost(this.userId).subscribe(res =>
      {
        this.spinner.hide();
       // console.log(res);
        this.patchMedia(res.data);
        this.patchFields(res.data);
        this._toster.showSuccess(res.message);
      } , 
      (err) =>
      {
        this._toster.showError(err.error.message);
        this.spinner.hide();
        this.form.reset();
        this.url = null;
        this.thumbnailurl = null;
        this.videoInput = null;
        this.thumbnailInput = null;
      })

  }

  patchFields(data)
  {
    this.form.patchValue({
      title: data.title,
      description: data.description,
      lang : data.lang
    });

    JSON.parse(data.precautions).forEach((element,index) => {
      this.addPrecaution();
      this.form.get('precautions').get(`${index}`).get('precautionPoint').setValue(element);
    });

    if(!JSON.parse(data.precautions).length)
    {
      this.addPrecaution();
    }

    JSON.parse(data.symptoms).forEach((element,index) => {
      this.addSymptoms();
      this.form.get('symptoms').get(`${index}`).get('symptomPoint').setValue(element);
    });

    if(!JSON.parse(data.symptoms).length)
    {
      this.addSymptoms();
    }

  }

  patchMedia(data)
  {
    if(!data.embedded){
      this.form.get('videoFileName').setValue(data.mediaFileName);
      this.form.get('thumbnailFileName').setValue(data.thumbnailFileName);

      if(this.router.url.includes('localhost'))
      {
        
        this.url = `${this.localUrl}${data.mediaLink}`
        this.thumbnailurl = `${this.localUrl}${data.thumbnailLink}`
      }
      else{
        
        this.url = `${this.devUrl}${data.mediaLink}`
        this.thumbnailurl = `${this.devUrl}${data.thumbnailLink}`
      }
    }
    else
    {
      this.setVideoType(true);
      this.form.get('embedLink').setValue(data.mediaLink);
    }
    
  }

  onChangeFile(event)
  {
    const file = event.target.files && event.target.files[0];
    
    this.form.patchValue({
      video: file,
      videoFileName : file.name
    });

    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        this.url = (<FileReader>event.target).result;
      }
    }
  }

  thumbnail(event)
  {
    const file = event.target.files && event.target.files[0];
   
    this.form.patchValue({
      thumbnail: file,
      thumbnailFileName : file.name
    });

    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        this.thumbnailurl = (<FileReader>event.target).result;
      }
    }
  }



  get precautions() : FormArray {
    return this.form.get("precautions") as FormArray
  }

  get symptoms() : FormArray {
    return this.form.get("symptoms") as FormArray
  }

  newPrecautionPoint(): FormGroup {
    return this.fb.group({
      precautionPoint: [""],
    });
 }

 newSymptomPoint(): FormGroup {
  return this.fb.group({
    symptomPoint: [""],
  });
  }

 addPrecaution() {
  this.precautions.push(this.newPrecautionPoint());
  }

  addSymptoms()
  {
    this.symptoms.push(this.newSymptomPoint());
  }

  removePrecaution(i) {
    this.precautions.removeAt(i);
  }

  removeSymptoms(i) {
    this.symptoms.removeAt(i);
  }

  validateForm()
    {
        const controls = this.form.controls;
      
        for (const name in controls) {
            if (controls[name].invalid) {
              console.log(name);
                this.invalid.push(name);
            }
        }

        console.log(this.form.get('embed').value)

        if(this.edit)
        {
          this.invalid.splice(this.invalid.indexOf('thumbnail') , 1);
          this.invalid.splice(this.invalid.indexOf('video') , 1);
        }

        if(!this.form.get('embed').value)
        {
          this.invalid.splice(this.invalid.indexOf('embedLink') , 1); 
        }
        else
        {
          this.invalid.splice(this.invalid.indexOf('thumbnail') , 1);
          this.invalid.splice(this.invalid.indexOf('video') , 1);
        }
      
        console.log(this.invalid);
        return this.invalid.length ? false : true;
    }

  submit()
  {
    if(this.validateForm()){

    this.spinner.show();
    const payloadObj = {};
    const precautionArr = [];
    const symptomsArr = [];

    for (const key in this.form.value) {
      payloadObj[key] = this.form.get(key).value ;
    }

    this.form.get('precautions').value?.forEach(element => {
      element['precautionPoint'] = element['precautionPoint'].trim();
      if(element['precautionPoint'].length)
      {
        precautionArr.push(element["precautionPoint"]);
      }
    });

    this.form.get('symptoms').value?.forEach(element => {
      element['symptomPoint'] =element['symptomPoint'].trim();
      if(element['symptomPoint'].length)
      {
        console.log("symprtom point " , element['symptomPoint']);
        symptomsArr.push(element["symptomPoint"]);
      }
    });

    payloadObj['precautions'] = precautionArr;
    payloadObj['symptoms'] = symptomsArr;
    
    this.spinner.show();

    this.cmsService.addPost(payloadObj).subscribe(res =>
      {
        this.spinner.hide();
        this._toster.showSuccess(res.message);
        this.router.navigate(['/cms-listing']);
      } , 
      (err) =>
      {
        this._toster.showError(err.error.message);
        this.spinner.hide();
        // this.form.reset();
        // this.url = null;
        // this.thumbnailurl = null;
        // this.videoInput = null;
        // this.thumbnailInput = null;
      })
    }
  }

  reset()
  {
    this.form.reset();
    this.url = null;
    this.thumbnailurl = null;
    this.videoInput = null;
    this.thumbnailInput = null;
    this.form.get('embed').setValue(false);
    this.form.get('lang').setValue("");
    console.log(this.form);
  }

  editPost()
  {
    console.log(this.form.value);
    if(this.validateForm()){
    this.spinner.show();
    const payloadObj = {};
    const precautionArr = [];
    const symptomsArr = [];

    for (const key in this.form.value) {
      payloadObj[key] = this.form.get(key).value ;
    }

    this.form.get('precautions').value?.forEach(element => {
      element['precautionPoint'] = element['precautionPoint'].trim();
      if(element['precautionPoint'].length)
      {
        precautionArr.push(element["precautionPoint"]);
      }
    });

    this.form.get('symptoms').value?.forEach(element => {
      element['symptomPoint'] =element['symptomPoint'].trim();
      if(element['symptomPoint'].length)
      {
        console.log("symprtom point " , element['symptomPoint']);
        symptomsArr.push(element["symptomPoint"]);
      }
    });

    payloadObj['precautions'] = precautionArr;
    payloadObj['symptoms'] = symptomsArr;

    this.cmsService.editPost(payloadObj , this.userId).subscribe(res =>
      {
        this.spinner.hide();
        this._toster.showSuccess(res.message);
        this.router.navigate(['/cms-listing']);
      } , 
      (err) =>
      {
        this._toster.showError(err.error.message);
        // this.setFormValues();
      })
    }
  }

  embedLinkfunc($event)
  {
    console.log(this.form.get('embedLink').value);
    
  }

  clearValidationMessage(key)
    {
        if(this.invalid.includes(key))
        {
            this.invalid.splice(this.invalid.indexOf(key) , 1);
        }
    }

  setVideoType(flag)
  {
    
    if(flag)
    {
      this.url = null;
      this.thumbnailurl = null;
      this.videoInput = null;
      this.thumbnailInput = null;
      this.form.get('embed').setValue(true);
      this.form.get('thumbnail').setValue('');
      this.form.get('video').setValue('');
      this.form.get('thumbnailFileName').setValue('');
      this.form.get('videoFileName').setValue('');
      
    }
    else
    {
      this.form.get('embedLink').setValue('');
      this.form.get('embed').setValue(false); 
      
    }
  }



  back() {
    
    this.location.back(); // 
  }
  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }
}
