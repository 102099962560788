import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifectionService } from 'src/app/services/notifection.service';
import { UserServiceService } from 'src/app/services/user-service.service';

@Component({
  selector: 'app-patient-management',
  templateUrl: './patient-management.component.html',
  styleUrls: ['./patient-management.component.css']
})
export class PatientManagementComponent implements OnInit {

  allUsers:any;
  addUserForm:FormGroup
  isDataTable:boolean = true;
  updateUser:FormGroup
  userId:any;
  defaultAuthentication: string = 'Admin';
 message:boolean=false;
 alert:any;
 datas:any;
 error_message:boolean=false;
 selectedValue : any ; 
 element;
 page = 1 ;
 totalItem = 0;
limit = 10;
search='';
form;
  constructor(private userService: UserServiceService , private fb:FormBuilder , private _toster: NotifectionService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      search : ['']
    });
    this.getUsers();
  }

  loadExternalScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  searchData()
  {
    this.search = this.form.get('search').value;
    this.getUsers();
  }

  getPgaeObj($event)
  {
    this.page = $event;
    this.getUsers();
  }
  
  getUsers() {
    this.spinner.show();
    this.userService.getPatientData(this.page , this.limit , this.search).subscribe((res)=>{
      this.allUsers = res['data']
      this.totalItem = res['total'];
    //  console.log("All users ",this.allUsers)
      this.spinner.hide();
      if (this.isDataTable) {
        this.loadExternalScript('assets/js/datatables.js');
        this.isDataTable=false;
       }
    },
    (err) => {
      this._toster.showError("No Internet Connection!");
      this.spinner.show();
      this.allUsers=[];
    }
    )
  }

  
  toggleUserStatus($event , user  , index)
  {
   
  //  console.log($event.srcElement.checked);
    const data = { status : $event.srcElement.checked , email :  user.email};

    this.spinner.show();
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
    this.userService.setstatus(data).subscribe((res)=>{
      //console.log(res['data']);
      // this.allUsers[index] = res['data'];
      this.getUsers();
      this.spinner.hide();
      this._toster.showSuccess(res['message'])
    },
    (err) => {
      this._toster.showError(err.error.message);
      this.spinner.hide();
      
    }
    )
  }

  
  clear()
  {
    this.form.controls['search'].setValue('');
    this.search = '';
    this.getUsers();
  }

  
  FadeOutSuccessMsg() {
    setTimeout( () => {
           this.message = false;
        }, 5000);
   }

   FadeOutErrorMsg() {
    setTimeout( () => {
           this.error_message = false;
        }, 5000);
   }

  delete(id)
  {
    if (confirm("Are you sure to delete the record ? ")) {
    this.spinner.show();
    this.userService.deleteUser(id).subscribe(res =>
      {
        this.spinner.hide();
        if(res.success)
        {
          this._toster.showSuccess(res.message);
          this.getUsers();
        }
      },err =>
      {
        this._toster.showError(err.error.message);
        this.spinner.hide();
      })
    }
  }
}
