<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<div class="main-content">
    <div class="title m-auto d-flex justify-content flex-column col-md-8 mb-3">
        <h3>Edit Details</h3>
        <button class="form-control btn btn-primary" (click)="cancel()" style="margin-left: 83%; width: 14%;"
            type="button">
            Go Back
        </button>
    </div>

    <div class="mb-5 mt-5">
        <div [formGroup]="form" class="d-flex justify-content flex-column form-group col-md-8 m-auto">
            <!-- <div class="mb-3 d-flex flex-column align-items-start">
                <label for="name">Content</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control" onkeypress="return event.keyCode !== 32" formControlName="content">
                </div>
                <span *ngIf="this.invalid?.includes('content')" class="danger"><span
                        class="fas fa-info-circle mr-2"></span>Content is required !</span>

            </div> -->
            <div class="mb-3 d-flex flex-column align-items-start">
                <div>
                    <label for="images">Image</label>
                </div>
                <div class="input-group mb-3">
                    <input type="file" class="form-control" multiple="true" accept="image/jpeg,image/png"
                        formControlName="images" (mouseout)="clearValidationMessage('image')"
                        (change)="onFileChange($event)" placeholder="Enter the speciality image" name="image">
                </div>
                <div *ngIf="imageValues.length > 0" class="row">
                    <div *ngFor="let file of imageValues; let i = index">
                        <div>
                            <img [src]="file?.filename" class="img-thumbnail"
                                style="width: 100px; height: 90px; margin-left:20px">
                            <i *ngIf="imageValues.length > 1" class="fas fa-trash" (click)="onDeletePreviousImage(i)"
                                style="cursor: pointer; color:#1c6d4e ; margin-left:8px"></i>
                        </div>
                    </div>
                </div>
                <span *ngIf="this.invalid?.includes('images')" class="danger"><span
                        class="fas fa-info-circle mr-2"></span>Image is required !</span>
            </div>
        </div>
    </div>
    <div class="col-md-8 m-auto">
        <button class="btn btn-info mr-2" (click)="edit()">Edit</button>
        <button class="btn btn-light" (click)="cancel()">Cancel</button>
    </div>
</div>