import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifectionService } from 'src/app/services/notifection.service';
import { Location } from '@angular/common';
import { UserServiceService } from "src/app/services/user-service.service";

@Component({
    selector: 'app-withdrawal-edit',
    templateUrl: './withdrawal-edit.component.html',
    styleUrls: ['./withdrawal-edit.component.css']
})
export class WithdrawalEditComponent implements OnInit, OnDestroy {
    form: FormGroup;
    invalid: string[] = [];
    ID: string;
    routeSub: any;

    constructor(
        private spinner: NgxSpinnerService,
        private _toster: NotifectionService,
        private userService: UserServiceService,
        private router: Router,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private location: Location
    ) {
        this.routeSub = this.route.params.subscribe(params => {
            this.ID = params['id'];
        });
    }

    ngOnInit(): void {
        this.createForm();
        this.getWithdrawalDetails()
    }

    createForm() {
        this.form = this.fb.group({
            name: [''],
            amount: [''],
            status: [''],
            date:[''],
            requestAmount: ['']
        });
    }

    clearValidationMessage(key: string) {
        const index = this.invalid.indexOf(key);
        if (index !== -1) {
            this.invalid.splice(index, 1);
        }
    }

    validateForm() {
        this.invalid = [];
        const controls = this.form.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                this.invalid.push(name);
            }
        }
        return this.invalid.length === 0;
    }

    cancel() {
        this.location.back();
    }

    edit() {
        if (this.validateForm()) {
            const formData = new FormData();
            this.spinner.show();

            formData.append('status', this.form.get('status')?.value);
            formData.append('id', this.ID);
            let updateObj={
                id:this.ID,
                status:this.form.get('status')?.value
            }
            this.userService.updateWithdrawal(updateObj).subscribe(
                res => {
                    this.spinner.hide();
                    this._toster.showSuccess(res.message);
                    this.location.back();
                },
                err => {
                    this.spinner.hide();
                    this._toster.showError(err.error.message);
                }
            );
        }
    }


    getWithdrawalDetails(){
   
        this.userService.getWithdrawalReqById(this.ID).subscribe((res) => {
            this.spinner.hide();
          
            this.form.patchValue({
                name: res.data?.doctor_id?.username,
                requestAmount:res.data?.amount,
                date:res.data?.date,
                status:res.data?.status,
                amount:res.data?.doctor_id?.totalIncome
            });
         
          //  this._toster.showSuccess(res.message);
        },
            (err) => {
                this.spinner.hide();
                this._toster.showError(err.error.message);
            })
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe();
    }
}
