<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
	<p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<!-- Main Content -->
<div class="main-content">
	<div class="title m-auto d-flex justify-content flex-column col-md-8 mb-3">
		
		<div class="row">
			<h3 style="margin-left:3%;">Post Form</h3>
			<button class="form-control" (click)="back()" style="margin-left: 58%;" class="btn btn-primary " type="submit">
				Go Back
			</button>
		</div>
	</div>
	<div class="mb-5 mt-5" >
	<div [formGroup]="form" class="d-flex justify-content flex-column form-group col-md-8 m-auto">
		<div class="mb-3 d-flex flex-column align-items-start">
			<div>
				<label for="title">Title</label>
			</div>
			<div class="input-group mb-3">
				<input type="text" class="form-control" formControlName="title"  placeholder="Enter the post title" name="title" (keyup)="clearValidationMessage('title')">
			</div>	
			<span *ngIf="this.invalid.includes('title')" class="danger"><span class="fas fa-info-circle mr-2"></span>Title is required !</span>
			<span *ngIf="this.form.get('title').value?.length > 50"  class="danger"><span class="fas fa-info-circle mr-2"></span>Title should be less than 50 characters!</span>	
		</div>

		<div class="mb-3 d-flex flex-column align-items-start">
			<div>
				<label  for="description">Description</label>
			</div>
			<div class="input-group mb-3">
				<input type="text" class="form-control" formControlName="description"  placeholder="Enter the post description" name="description" (keyup)="clearValidationMessage('description')">
			</div>	
			<span *ngIf="this.invalid.includes('description')" class="danger"><span class="fas fa-info-circle mr-2"></span>Description is required !</span>	
			<span *ngIf="this.form.get('description').value?.length > 100"  class="danger"><span class="fas fa-info-circle mr-2"></span>Description should be less than 100 characters!</span>	
		</div>

		<div class="mb-3">
			<div>
				<label>Video Type</label>
			</div>
			<select class="form-control" formControlName="embed" placeholder="select type" (change)="onChangeVideoType()">
				<option *ngFor="let item of vidTyp" value="{{item.value}}">{{item.key}}</option>
			  </select>
		</div>

		<div class="mb-3 d-flex flex-column align-items-start">
			<div>
				<label for="Upload File">Upload the video</label>
			</div>
			<div class="input-group mb-3">
				<input type="file" class="form-control input-file"  #videoInput accept="video/*" readonly placeholder="Upload File" aria-label="Upload File" aria-describedby="basic-addon2" (change)="onChangeFile($event)">
				<input type="text" class="form-control" disabled placeholder="Upload File" formControlName="videoFileName" >
				<!-- <div class="input-group-append"> -->
					<span class="input-group-text browse" [ngClass]="{'disabled': this.form.get('embed').value}" id="basic-addon2" (click)="videoInput.click(); clearValidationMessage('video')">Browse</span>
				<!-- </div> -->
			</div>	
			<span *ngIf="this.invalid.includes('video') && !this.form.get('embed').value" class="danger"><span class="fas fa-info-circle mr-2"></span>Post video is required !</span>	
		</div>
		

		<div class="mb-3 d-flex flex-column align-items-start">
			<div>
				<label for="Upload thumbanil">Upload the Thumbnail</label>
			</div>
			<div class="input-group mb-3">
				<input type="file" class="form-control input-file" #thumbnailInput  accept="image/*" readonly placeholder="Upload thumbanil" aria-label="Upload thumbanil" aria-describedby="basic-addon2" (change)="thumbnail($event)">
				
				<input type="text" class="form-control" #thumbnailInput disabled placeholder="Upload thumbnail" formControlName="thumbnailFileName">
				
				<div class="input-group-append">
					<span class="input-group-text browse" [ngClass]="{'disabled': this.form.get('embed').value}" id="basic-addon2" (click)="thumbnailInput.click(); clearValidationMessage('thumbnail')">Browse</span>
				</div>
			</div>		
			<span *ngIf="this.invalid.includes('thumbnail') && !this.form.get('embed').value" class="danger"><span class="fas fa-info-circle mr-2"></span>Thumbnail picture is required !</span>	
		</div>
		
		<div class="mb-3 d-flex flex-column align-items-start">
			<div>
				<label for="embedLink">Embed Link</label>
			</div>
			<div class="input-group mb-3">
				<input [ngClass]="{'disabled': !this.form.get('embed').value}" (keyup)="embedLinkfunc($event); clearValidationMessage('embedLink')"  class="form-control" formControlName="embedLink"  placeholder="Enter the youtube embed link" name="embedLink" >
			</div>	
			<span *ngIf="this.invalid.includes('embedLink') && this.form.get('embed').value" class="danger"><span class="fas fa-info-circle mr-2"></span>Embed Link is required !</span>	
		</div>

		<div class="d-flex">
		<ng-container *ngIf="url"><video #video [src]="url"  alt="" height="300" width="400" controls> </video> <br/> <br/></ng-container>
		<ng-container *ngIf="thumbnailurl" ><img [src]="thumbnailurl" height="300" width="400"> <br/></ng-container>
		</div>


		<div class="mb-3 d-flex flex-column" formArrayName="precautions">
			<div>
				<label for="precaution">Precautions</label>
			</div>
			<ng-container *ngFor="let item of precautions?.controls; let i=index">
				<ng-container [formGroupName]="i">
					<div class="input-group mb-3">
						<textarea class="form-control" formControlName="precautionPoint"  placeholder="Add a precaution" name="precaution" ></textarea>
						<div>
							<button class="btn btn-info plus" (click)="addPrecaution()" *ngIf="precautions.controls.length &&precautions.controls.length - 1 == i"> <span>+</span></button>
							<button class="btn btn-primary minus" (click)="removePrecaution(i)" *ngIf=" precautions.controls.length > 1"> <span>-</span></button>
						</div>
					</div>	
				</ng-container>
			</ng-container>
		</div>

		<div class="mb-3 d-flex flex-column" formArrayName="symptoms">
			<div>
				<label for="symptom">Symptoms</label>
			</div>
			<ng-container *ngFor="let item of symptoms?.controls; let i=index">
				<ng-container [formGroupName]="i">
					<div class="input-group mb-3">
						<textarea class="form-control" formControlName="symptomPoint"  placeholder="Add a symptom" name="symptom" ></textarea>
						<div>
							<button class="btn btn-info plus" *ngIf="symptoms.controls.length &&symptoms.controls.length - 1 == i"  (click)="addSymptoms()"> <span>+</span></button>
							<button class="btn btn-primary minus" (click)="removeSymptoms(i)" *ngIf="symptoms.controls.length > 1"> <span>-</span></button>
						</div>
					</div>
				</ng-container>
			</ng-container>
		</div>

		<!-- <div class="mb-3">
			<div>
				<label>Select the category</label>
			</div>
			<select class="form-control" placeholder="select category">
				<option value="" disabled selected hidden>Choose a Category</option>
				<option>My category</option>
			  </select>
		</div> -->

		<div class="mb-3 d-flex flex-column align-items-start">
			<div>
				<label>Language</label>
			</div>
			<select class="form-control" formControlName="lang" placeholder="select category" (change)="clearValidationMessage('lang')">
				<option value="" disabled selected hidden>Choose a Language</option>
				<option *ngFor="let item of language" value="{{item.key}}">{{item.value}}</option>
			</select>
			<span *ngIf="this.invalid.includes('lang')" class="danger"><span class="fas fa-info-circle mr-2"></span>Language is required !</span>
		</div>

	</div>
	<div class="col-md-8 m-auto">
		<button *ngIf="!edit" class=" btn btn-info mr-2" (click)="submit()">Submit</button>
		<button *ngIf="edit" class=" btn btn-info mr-2" (click)="editPost()">Edit</button>
		<button class="btn btn-light" (click)="reset()">Reset</button>
	</div>
	</div>


</div>